import Cookies from "js-cookie";

const TokenKey = "token";
const Username = "username";

export function isLogged() {
     return !!Cookies.get(TokenKey);
}

export function setToken(token, expire) {
     const ms = expire * 1000; // Convert seconds to milliseconds
     const expires_in = new Date(new Date().getTime() + ms);

     return Cookies.set(TokenKey, token, { expires: expires_in });
}

export function getToken() {
     return Cookies.get(TokenKey);
}

export function removeToken() {
     return Cookies.remove(TokenKey);
}

export function setUsername(username, expire) {
     const ms = expire * 1000; // Convert seconds to milliseconds
     const expires_in = new Date(new Date().getTime() + ms);

     return Cookies.set(Username, username, {
          expires: expires_in,
          secure: true,
          sameSite: "none"
     });
}

export function removeUsername() {
     return Cookies.remove(Username);
}
