import {
     getState as getStateAPI,
     createTicket as createTicketAPI
} from "api/utility.js";
import { getCoins as getCoinsAPI } from "api/utility.js";
import { defineStore } from "pinia";
import { authStore } from "store/auth.js";

export const utilitiesStore = defineStore("utilities", {
     state: () => {
          return {
               state: [],
               error_msg: {},
               selected_coin: null,
               loading: true,
               menuAuth: false,
               menuGuest: false
          };
     },
     actions: {
          getState() {
               if (this.state.length != 0) {
                    return;
               }

               return new Promise((resolve, reject) => {
                    getStateAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.state = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          createTicket(form) {
               return new Promise((resolve, reject) => {
                    createTicketAPI(form)
                         .then(() => {
                              // state.error_msg = {};
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg =
                                             error.response.data.errors;
                                   }
                              });
                              reject(error);
                         });
               });
          },
          clearErrors() {
               this.$patch((state) => {
                    state.error_msg = {};
               });
          },
          getCoins(param) {
               return new Promise((resolve, reject) => {
                    getCoinsAPI(param)
                         .then((response) => {
                              this.$patch((state) => {
                                   state.selected_coin = response.coins;
                              });

                              resolve(response);
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        if (
                                             error.response.status == 503 &&
                                             ["time", "days"].includes(
                                                  error.response.data?.type
                                             )
                                        ) {
                                             const useAuthStore = authStore();

                                             useAuthStore.setSelfExclusionTimer(
                                                  error.response.data
                                             );
                                             return;
                                        }

                                        if (error?.response?.data?.message) {
                                             state.selected_coin =
                                                  error.response.data.message;
                                        } else {
                                             state.selected_coin =
                                                  error.response.data;
                                        }
                                   }
                              });
                              reject(error);
                         });
               });
          },
          disableScroll() {
               const app_env = import.meta.env.VITE_ENV;
               if (app_env == "production") {
                    const chatButtonElement =
                         document.getElementById("chat-button");

                    const chatWidgetElement = document.getElementById(
                         "chat-widget-container"
                    );
                    const chatEyeCatcherElement = document.getElementById(
                         "livechat-eye-catcher"
                    );

                    if (chatButtonElement) {
                         chatButtonElement.style.display = "none";
                    }
                    if (chatWidgetElement) {
                         chatWidgetElement.style.display = "none";
                    }
                    if (chatEyeCatcherElement) {
                         chatEyeCatcherElement.style.display = "none";
                    }
               } else {
                    const chatButtonElement =
                         document.getElementById("chat-button");

                    const chatWidgetElement = document.getElementById(
                         "chat-widget-container"
                    );
                    const chatEyeCatcherElement = document.getElementById(
                         "livechat-eye-catcher"
                    );

                    if (chatButtonElement) {
                         chatButtonElement.style.display = "none";
                    }
                    if (chatWidgetElement) {
                         chatWidgetElement.style.display = "none";
                    }
                    if (chatEyeCatcherElement) {
                         chatEyeCatcherElement.style.display = "none";
                    }

                    const chaportElement =
                         document.querySelector(".chaport-container");

                    if (chaportElement) {
                         chaportElement.style.display = "none";
                    }
               }
               document.body.style.overflow = "hidden";
          },
          // Enable scroll
          enableScroll() {
               const app_env = import.meta.env.VITE_ENV;
               if (app_env == "production") {
                    const chatButtonElement =
                         document.getElementById("chat-button");
                    const chatWidgetElement = document.getElementById(
                         "chat-widget-container"
                    );
                    const chatEyeCatcherElement = document.getElementById(
                         "livechat-eye-catcher"
                    );

                    if (chatButtonElement) {
                         if (chatButtonElement.style.display === "none") {
                              chatButtonElement.style.display = "";
                         }
                    }

                    if (chatWidgetElement) {
                         if (chatWidgetElement.style.display === "none") {
                              chatWidgetElement.style.display = "";
                         }
                    }

                    if (chatEyeCatcherElement) {
                         if (chatEyeCatcherElement.style.display === "none") {
                              chatEyeCatcherElement.style.display = "";
                         }
                    }
               } else {
                    const chatButtonElement =
                         document.getElementById("chat-button");
                    const chatWidgetElement = document.getElementById(
                         "chat-widget-container"
                    );
                    const chatEyeCatcherElement = document.getElementById(
                         "livechat-eye-catcher"
                    );

                    if (chatButtonElement) {
                         if (chatButtonElement.style.display === "none") {
                              chatButtonElement.style.display = "";
                         }
                    }

                    if (chatWidgetElement) {
                         if (chatWidgetElement.style.display === "none") {
                              chatWidgetElement.style.display = "";
                         }
                    }

                    if (chatEyeCatcherElement) {
                         if (chatEyeCatcherElement.style.display === "none") {
                              chatEyeCatcherElement.style.display = "";
                         }
                    }

                    const chaportElement =
                         document.querySelector(".chaport-container");

                    if (chaportElement) {
                         if (chaportElement.style.display === "none") {
                              chaportElement.style.display = "";
                         }
                    }
               }
               document.body.style.overflow = "";
          },
          getCardTypeName(type) {
               let card = "";

               switch (type) {
                    case "VI":
                         card = "Visa";
                         break;
                    case "MC":
                         card = "MasterCard";
                         break;
                    case "AX":
                         card = "American Express";
                         break;
                    case "DI":
                         card = "Discover";
                         break;
                    case "JCB":
                         card = "JCB";
                         break;
                    case "DINERS":
                         card = "Diners";
                         break;
                    case "MAES":
                         card = "Maestro";
                         break;
                    case "CUP":
                         card = "UnionPay";
                         break;
                    case "VE":
                         card = "Visa Electron";
                         break;
                    case "CB":
                         card = "Carte Bancaire";
                         break;
                    case "INT":
                         card = "Interac";
                         break;
                    case "SOLO":
                         card = "Solo";
               }

               return card;
          },
          // Enable scroll
          preLoading(data) {
               this.$patch((state) => {
                    state.loading = data;
               });
          },
          // set menu
          setMenuAuth(data) {
               this.$patch((state) => {
                    state.menuAuth = data;
               });
          },
          setMenuGuest(data) {
               this.$patch((state) => {
                    state.menuGuest = data;
               });
          }
     }
});
