export default function device() {
     const deviceType = () => {
          var type =
               /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
               )
                    ? "mobile"
                    : "desktop";

          return type;
     };

     const isIOS = () => {
          return (
               /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
          );
     };

     const browserType = () => {
          let userAgent = navigator.userAgent.toLowerCase();

          // Check for different browsers
          if (
               userAgent.indexOf("chrome") > -1 &&
               userAgent.indexOf("edg") === -1 &&
               userAgent.indexOf("opr") === -1
          ) {
               return "google";
          } else if (userAgent.indexOf("firefox") > -1) {
               return "firefox";
          } else if (
               userAgent.indexOf("safari") > -1 &&
               userAgent.indexOf("chrome") === -1
          ) {
               return "safari";
          } else if (userAgent.indexOf("edg") > -1) {
               return "edge";
          } else if (
               userAgent.indexOf("msie") > -1 ||
               userAgent.indexOf("trident") > -1
          ) {
               return "internet explorer";
          } else if (userAgent.indexOf("opr") > -1) {
               return "opera";
          } else {
               return "unknown";
          }
     };

     return {
          deviceType,
          isIOS,
          browserType
     };
}
