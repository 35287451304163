<template>
     <Slider
          v-if="type == 'slider'"
          :data="games"
          title="Top Games"
          :onNextSlide="() => {}"
          :pagination="null"
          game_type="top-games"
          :onSetActive="onSetActive"
     >
          <template v-slot:icon>
               <span v-html="decodeIcon(icon)"></span>
          </template>
     </Slider>
     <Paginate
          v-else-if="type == 'paginate'"
          :data="games"
          title="Top Games"
          :onSeeMore="() => {}"
          :pagination="null"
          game_type="top-games"
     >
          <template v-slot:icon>
               <span v-html="decodeIcon(icon)"></span>
          </template>
     </Paginate>
</template>
<script>
import { reactive, watch, computed } from "vue";
import { gamesStore } from "store/games";
import Slider from "components/games/slider/index.vue";
import Paginate from "components/games/paginate/index.vue";
import { useFormatter } from "composables/use-formatter.js";

export default {
     components: {
          Slider,
          Paginate
     },
     props: ["type", "onSetActive", "icon"],
     setup() {
          const useGamesStore = gamesStore();

          const { decodeIcon } = useFormatter();

          const games = reactive({
               data: null
          });

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const popularData = computed(() => {
               if (useGamesStore.popular) {
                    games.data = useGamesStore.popular["games"]["data"];
                    pagination["current"] =
                         useGamesStore.popular["games"]["current_page"];
                    pagination["last"] =
                         useGamesStore.popular["games"]["last_page"];
                    const temp =
                         useGamesStore.popular["games"]["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.split("=")[1] : pagination["last"];
                    // setNextLimit();
               }
               return useGamesStore.popular || null;
          });

          watch(popularData, (newVal) => {
               if (newVal) {
                    games.data = newVal["games"]["data"];
                    pagination["current"] = newVal["games"]["current_page"];
                    pagination["last"] = newVal["games"]["last_page"];
                    const temp = newVal["games"]["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.split("=")[1] : pagination["last"];
               }
          });

          return {
               games,
               pagination,
               decodeIcon
          };
     }
};
</script>
