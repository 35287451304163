import request from "@/utils/request";

export function transaction_history(data) {
     return request({
          url: "/transaction/transaction_history",
          method: "get",
          params: data
     });
}

export function make_withdraw(data) {
     return request({
          url: "/transaction/withdraw/make_withdraw",
          method: "post",
          data: data
     });
}

export function make_withdraw_cc(data) {
     return request({
          url: "/transaction/withdraw/make_withdraw_cc",
          method: "post",
          data: data
     });
}

export function getWithdraw() {
     return request({
          url: "/transaction/withdraw/redeemable",
          method: "get"
     });
}

export function limitTransaction() {
     return request({
          url: "/transaction/bank/settings",
          method: "get"
     });
}

export function worldpayStatus() {
     return request({
          url: "/transaction/worldpay/status",
          method: "get"
     });
}

export function bankStatus() {
     return request({
          url: "/transaction/bank/button",
          method: "get"
     });
}
