<template>
     <InertiaHead title="Unique Access Code"></InertiaHead>
     <div class="w-full h-full relative mt-0">
          <div class="w-full bg-transparent relative">
               <div
                    class="w-full h-full flex flex-col justify-center items-center pb-16 sm:pb-18 md:pb-20 lg:pb-28"
               >
                    <div
                         v-if="isSelfExclusion == null"
                         class="bg-background-1 rounded-md drop-shadow-2xl mt-18 md:mt-20 xl:mt-28 w-full lg:w-3/4 xl:w-7/12 2xl:w-5/12 md:w-2/3 px-5 md:px-8 xl:px-8 lg:px-8"
                    >
                         <div class="mt-4 xl:mt-8 mb-12">
                              <div
                                   class="text-3xl xl:text-4xl text-center xl:text-left uppercase text-white font-helvetica tracking-wide"
                              >
                                   Generate your unique request code
                              </div>
                              <div
                                   class="pt-10 font-helveticaLight text-white text-sm xl:text-md"
                              >
                                   <div class="font-medium tracking-wide">
                                        Please click the ‘<span
                                             class="text-primal-yellow font-bold"
                                             >Request Code</span
                                        >’ button to get your special one-time
                                        code.
                                   </div>
                                   <div class="font-medium tracking-wide">
                                        After that, you'll see a
                                        <span
                                             class="text-primal-yellow font-bold"
                                             >10-digit code</span
                                        >. We suggest taking a screenshot or
                                        writing down this code because you'll
                                        need it for the next step.
                                   </div>
                                   <div
                                        class="p-2 bg-moozi-1 rounded-md"
                                        :class="
                                             isNoNameSet
                                                  ? 'mt-10 mb-5'
                                                  : 'my-10'
                                        "
                                   >
                                        <p class="text-sm text-white">
                                             Remember, clicking the button
                                             multiple times won't give you a new
                                             code. Each code is only good for
                                             one request. Only codes created on
                                             this specific page are allowed for
                                             the request process.
                                        </p>
                                   </div>
                                   <div
                                        v-if="isNoNameSet"
                                        class="p-2 bg-moozi-1 mb-10 rounded-md"
                                   >
                                        <p
                                             class="text-sm text-white font-normal"
                                        >
                                             Please set your first name and last
                                             name on
                                             <span
                                                  @click="goToProfile"
                                                  class="underline underline-offset-4 cursor-pointer"
                                             >
                                                  Account Details </span
                                             >.
                                        </p>
                                   </div>
                              </div>

                              <!-- Unique Code Display -->
                              <div
                                   v-if="unique_code != null"
                                   class="pt-6"
                              >
                                   <div
                                        class="blue-box px-0 md:px-2 lg:px-3 xl:px-3 py-4"
                                   >
                                        <div
                                             class="font-helveticaLight text-white font-light text-sm lg:text-md pb-2"
                                        >
                                             Your unique code :
                                        </div>

                                        <form class="flex flex-row">
                                             <input
                                                  type="text"
                                                  class="w-10/12 lg:w-4/5 xl:w-4/5 mr-0 md:mr-2 lg:mr-2 xl:mr-2 border bg-background-1 font-inter border-white tracking-widest text-sm xl:text-xl lg:text-xl px-2 py-2 text-center rounded-full block text-white"
                                                  :value="unique_code.code"
                                                  disabled
                                             />
                                             <div
                                                  class="px-1 flex justify-center"
                                             >
                                                  <button
                                                       @click.prevent="
                                                            getCode(
                                                                 unique_code.remen
                                                            )
                                                       "
                                                       class="font-bold bg-white border rounded-full text-black px-4 md:px-6 lg:px-6 xl:px-6 md:w-32 lg:w-32 xl:w-32 py-2 md:py-2 lg:py-4 xl:py-4"
                                                  >
                                                       Copy
                                                  </button>
                                             </div>
                                        </form>
                                        <div
                                             class="font-inter font-light text-white text-sm xl:text-md pt-4"
                                        >
                                             This unique code is valid for one
                                             user. Wait a few minutes to request
                                             a new code.
                                        </div>
                                   </div>
                              </div>

                              <!-- Conditional Form Section for Captcha -->
                              <div v-if="showCaptcha">
                                   <div class="space-y-3 pt-6">
                                        <div
                                             class="flex flex-col justify-center items-center"
                                        >
                                             <div
                                                  class="flex flex-col items-center"
                                             >
                                                  <div
                                                       class="flex-1 flex justify-center mb-2"
                                                  >
                                                       <img
                                                            :src="captcha?.img"
                                                            @click="
                                                                 resetCaptcha()
                                                            "
                                                            class="w-auto h-12 max-w-full cursor-pointer"
                                                       />
                                                  </div>
                                                  <div class="flex-1 w-full">
                                                       <input
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5"
                                                            type="tel"
                                                            name="captcha"
                                                            placeholder="Enter captcha"
                                                            v-model="
                                                                 form.captcha
                                                            "
                                                            @change="
                                                                 clearFormError(
                                                                      'captcha'
                                                                 )
                                                            "
                                                            :class="
                                                                 form.errors
                                                                      ?.errors
                                                                      ?.captcha
                                                                      ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                                      : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                                                            "
                                                       />
                                                  </div>
                                             </div>
                                             <has-error-form
                                                  v-model:form="form"
                                                  :field="'captcha'"
                                             ></has-error-form>
                                             <button
                                                  @click="getUniqueCodeLoad"
                                                  type="button"
                                                  class="font-roboto text-black bg-secondary-3 mt-4 px-16 font-bold py-2 text-lg rounded-full tracking-wide"
                                                  :class="{
                                                       '!bg-gray-190 text-shade-7 cursor-not-allowed':
                                                            !form.captcha ||
                                                            isNoNameSet
                                                  }"
                                                  :disabled="
                                                       !form.captcha ||
                                                       isNoNameSet
                                                  "
                                             >
                                                  Request Code
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <SelfExclusionAlert
                         v-else
                         class="pt-18 sm:pt-20 lg:pt-28 xl:pt-32"
                    />
               </div>
          </div>
     </div>
     <loading-overlay :isLoading="loading" />
</template>

<script>
import { getUniqueCode, uniqueCodeStatus } from "api/auth.js";
import { Head as InertiaHead } from "@inertiajs/vue3";
import { onMounted, ref, reactive, computed, watch } from "vue";
import { authStore } from "store/auth.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useToaster } from "composables/use-toaster.js";
import { useScroll } from "composables/use-scroll.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import HasErrorForm from "components/haserror/index.vue";
import Form from "vform";
import _ from "underscore";
import { useRouter } from "vue-router";

export default {
     components: {
          InertiaHead,
          LoadingOverlay,
          SelfExclusionAlert,
          HasErrorForm
     },
     setup() {
          const router = useRouter();

          const useAuthStore = authStore();
          const loading = ref(false);
          const unique_code = ref(null);
          const countdown = ref(null);
          const showCaptcha = ref(false);
          const { successToast, errorToast } = useToaster();
          const { scrollTop } = useScroll();

          const isNoNameSet = ref(false);

          const form = reactive(
               new Form({
                    captcha: "",
                    key: ""
               })
          );

          const isUser = computed(() => {
               if (useAuthStore.user != null) {
                    isNoNameSet.value =
                         [null, ""].includes(useAuthStore.user.first_name) ||
                         [null, ""].includes(useAuthStore.user.last_name);
               }
               return useAuthStore.user;
          });

          const captcha = computed(() => {
               return useAuthStore.captcha;
          });

          const isSelfExclusion = computed(() => useAuthStore.isSelfExclusion);

          const resetCaptcha = () => {
               useAuthStore.getCaptcha();
          };

          const clearFormError = (key) => {
               if (form.errors?.errors[key]) {
                    delete form.errors.errors[key];
               }
          };

          const getCode = (code) => {
               const codeEl = document.createElement("textarea");
               codeEl.value = code;
               document.body.appendChild(codeEl);
               codeEl.select();
               document.execCommand("copy");
               document.body.removeChild(codeEl);
               successToast("Code is copied successfully!", "top-right");
          };

          // REQUEST CODE
          const getUniqueCodeLoad = async () => {
               if (isNoNameSet.value) {
                    return;
               }

               form.key = captcha.value?.key;
               loading.value = true;
               try {
                    await requestCode(form);
                    await useAuthStore.getUser();
                    form.captcha = ""; // Clear captcha input
                    resetCaptcha(); // Reset captcha image
                    successToast("Successfully Generated", "top-right");
                    loading.value = false;
               } catch (error) {
                    loading.value = false;
                    errorToast(
                         "Error getting unique code! Please try again.",
                         "top-right"
                    );
                    console.error("Error getting unique code:", error);
               }
          };

          const countDownTimer = () => {
               setInterval(() => {
                    if (!countdown.value) {
                         showCaptcha.value = true;
                    } else {
                         showCaptcha.value = false;
                         countdown.value--;
                    }
               }, 1000);
          };

          // REQUEST NEW CODE
          const requestCode = async (data) => {
               return new Promise((resolve, reject) => {
                    getUniqueCode(data)
                         .then((response) => {
                              if (!response) {
                                   reject("Failed to load unique code!");
                                   return;
                              }
                              unique_code.value = response;
                              countdown.value = response.countdown;

                              resolve();
                         })
                         .catch((error) => {
                              errorToast(
                                   "Error to load unique code!",
                                   "top-right"
                              );
                              reject(error);
                         });
               });
          };

          const codeStatus = async () => {
               return new Promise((resolve, reject) => {
                    uniqueCodeStatus().then((response) => {
                         if (!response) {
                              reject("Failed to load unique code!");
                              return;
                         }

                         if (response.countdown) {
                              countdown.value = response.countdown;
                         }

                         resolve();
                    });
               });
          };

          const openRequest = async () => {
               if (!isUser.value) {
                    router.push({ name: "login" });
               } else {
                    // Check if the countdown is null (indicating it's finished) before getting a new code
                    if (countdown.value === null) {
                         await getUniqueCodeLoad(); // Request a new unique code
                    }
               }
          };

          const handleCaptchaSubmit = async () => {
               if (form.captcha !== "" && countdown.value === null) {
                    await getUniqueCodeLoad(); // Trigger the unique code request on captcha submission
               }
          };

          const goToProfile = () => {
               router.push({
                    path: "/account/",
                    query: { tab: "account-details" }
               });
          };

          onMounted(() => {
               scrollTop();
               resetCaptcha();
               countDownTimer();
               codeStatus();
          });

          watch(isUser, async (newVal) => {
               if (newVal != null) {
                    isNoNameSet.value =
                         [null, ""].includes(newVal.first_name) ||
                         [null, ""].includes(newVal.last_name);

                    if (newVal.meta) {
                         let uniqueData = _.where(newVal.meta, {
                              meta_key: "unique_code"
                         });

                         if (typeof uniqueData !== "undefined") {
                              let lastestCode = _.sortBy(
                                   uniqueData,
                                   (item) => -item.created_at
                              );

                              if (typeof lastestCode[0] !== "undefined") {
                                   unique_code.value = {
                                        code: lastestCode[0].meta_value
                                   };
                              }
                         }
                    }
               }
          });

          return {
               loading,
               unique_code,
               form,
               captcha,
               isSelfExclusion,
               clearFormError,
               getCode,
               openRequest,
               getUniqueCodeLoad,
               handleCaptchaSubmit, // Expose the new method for captcha submission
               resetCaptcha,
               countdown,
               showCaptcha,
               isUser,
               isNoNameSet,
               goToProfile
          };
     }
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
