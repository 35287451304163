<template>
     <div
          v-if="cardDetails.length"
          class="w-full flex flex-col gap-2"
     >
          <div
               v-for="(item, key) in cardDetails"
               :key="key"
               class="w-full"
          >
               <tooltip
                    position="top"
                    class="!w-full"
                    :disable="item.paypage?.isAccepted ? true : false"
                    :class="item.paypage?.isAccepted ? '!opacity-100' : ''"
               >
                    <template v-slot:text>
                         <div
                              @click="
                                   item.paypage?.isAccepted
                                        ? onUpdateID(item.id, item)
                                        : null
                              "
                              class="flex flex-row items-center w-full gap-2 px-4 py-3 bg-gray-150 border border-solid rounded-lg"
                              :class="{
                                   'cursor-pointer border-shade-7':
                                        item.paypage?.isAccepted,
                                   'cursor-not-allowed border-shade-10':
                                        !item.paypage?.isAccepted,
                                   '!border-background-3':
                                        form.accountId == item.id
                              }"
                         >
                              <div
                                   class="w-full flex flex-row justify-between items-center gap-2"
                                   :class="
                                        item.paypage?.isAccepted
                                             ? 'cursor-pointer'
                                             : 'cursor-not-allowed'
                                   "
                              >
                                   <input
                                        v-if="item.paypage?.isAccepted"
                                        v-model="form.accountId"
                                        :id="item.id"
                                        :key="key"
                                        type="radio"
                                        name="existingCard"
                                        :value="item.id"
                                        class="h-4 w-4 border-gray-300 focus:ring-background-3 text-background-3"
                                        :class="
                                             item.paypage?.isAccepted
                                                  ? 'cursor-pointer'
                                                  : 'cursor-not-allowed'
                                        "
                                   />
                                   <div
                                        v-else
                                        class="rounded-full w-4"
                                   >
                                        <svg
                                             viewBox="0 0 22 22"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                        >
                                             <path
                                                  d="M11 7.66667H11.0111M9.88889 11H11V15.4444H12.1111M1 11C1 12.3132 1.25866 13.6136 1.7612 14.8268C2.26375 16.0401 3.00035 17.1425 3.92893 18.0711C4.85752 18.9997 5.95991 19.7363 7.17317 20.2388C8.38642 20.7413 9.68678 21 11 21C12.3132 21 13.6136 20.7413 14.8268 20.2388C16.0401 19.7363 17.1425 18.9997 18.0711 18.0711C18.9997 17.1425 19.7363 16.0401 20.2388 14.8268C20.7413 13.6136 21 12.3132 21 11C21 8.34784 19.9464 5.8043 18.0711 3.92893C16.1957 2.05357 13.6522 1 11 1C8.34784 1 5.8043 2.05357 3.92893 3.92893C2.05357 5.8043 1 8.34784 1 11Z"
                                                  stroke="#d07620"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                             />
                                        </svg>
                                   </div>
                                   <div
                                        class="w-full flex flex-row gap-2 justify-between items-center text-black text-xs md:text-sm lg:text-xs xl:text-sm"
                                        :class="
                                             item.paypage?.isAccepted
                                                  ? 'cursor-pointer'
                                                  : 'cursor-not-allowed opacity-50'
                                        "
                                   >
                                        <div class="flex flex-col">
                                             <span
                                                  class="capitalize flex flex-row items-center gap-1 font-bold text-moozi-1"
                                             >
                                                  {{ item.paypage?.card_name }}
                                                  <span
                                                       class="uppercase font-normal rounded-full md:px-2 text-mid py-0 w-2 md:w-auto h-2 md:h-max text-transparent md:text-white"
                                                       :class="
                                                            !item.paypage
                                                                 ?.isAccepted
                                                                 ? 'bg-secondary-5'
                                                                 : item.status ==
                                                                     1
                                                                   ? 'bg-shade-14'
                                                                   : 'bg-shade-15'
                                                       "
                                                  >
                                                       {{
                                                            !item.paypage
                                                                 ?.isAccepted
                                                                 ? "Not Supported"
                                                                 : item.status ==
                                                                     1
                                                                   ? "Active"
                                                                   : "Inactive"
                                                       }}
                                                  </span>
                                             </span>
                                             <span>
                                                  Account ending in
                                                  <span class="font-bold">
                                                       {{
                                                            item.paypage
                                                                 ?.lastFour
                                                       }}
                                                  </span>
                                             </span>
                                        </div>
                                        <div>
                                             <img
                                                  :class="
                                                       item.paypage?.type ==
                                                       'VI'
                                                            ? ''
                                                            : 'hidden'
                                                  "
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/card/visa-rounded.png'
                                                  "
                                                  class="h-6"
                                             />
                                             <img
                                                  :class="
                                                       item.paypage?.type ==
                                                       'MC'
                                                            ? ''
                                                            : 'hidden'
                                                  "
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/card/master-rounded.png'
                                                  "
                                                  class="h-6"
                                             />
                                             <img
                                                  :class="
                                                       item.paypage?.type ==
                                                       'DI'
                                                            ? ''
                                                            : 'hidden'
                                                  "
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/card/discover-rounded.png'
                                                  "
                                                  class="h-6"
                                             />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </template>
                    <template v-slot:tooltip>
                         <div class="w-64 lg:w-full p-2">
                              This card is not supported for redeems, but you
                              can use it for purchasing.
                         </div>
                    </template>
               </tooltip>
               <!-- @click="setSelectedCard(item)" -->
          </div>
     </div>
     <div
          v-else-if="cardDetails == null"
          class="h-32 w-full"
     >
          <div
               v-if="loading"
               class="w-full h-full flex items-center justify-center"
          >
               <svg
                    aria-hidden="true"
                    class="w-8 h-8 text-black animate-spin fill-secondary-1"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                         fill="currentColor"
                    ></path>
                    <path
                         d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                         fill="currentFill"
                    ></path>
               </svg>
          </div>
     </div>
     <div
          v-else
          class="w-full mt-3"
     >
          <div
               class="border border-shade-10 py-3 px-4 w-full text-center text-shade-10 text-sm lg:text-base rounded-lg mb-4"
          >
               No payment method. Please add a new card.
          </div>
     </div>
     <div class="w-full my-5">
          <span
               @click="openPaymentCheckout()"
               class="font-bold underline text-base cursor-pointer text-moozi-1"
          >
               <span class="text-lg">+</span>
               <span class="text-sm xl:text-base"> Add New Card </span>
          </span>
     </div>
     <div v-if="!hideForm">
          <div
               v-if="form.accountId != ''"
               class="w-full mt-3 mb-2"
          >
               <div class="flex items-center justify-start mb-2">
                    <p
                         class="text-shade-11 font-helveticaLight font-bold tracking-wide text-start sm:text-lg md:text-xl lg:text-lg xl:text-lg w-full"
                    >
                         Card Account Verification
                    </p>
               </div>
               <div class="px-2">
                    <div class="mb-2">
                         <label
                              for="card_number"
                              class="block mb-2 text-sm font-medium text-shade-2"
                         >
                              Card Number
                         </label>
                         <input
                              v-model="form.card_number"
                              type="text"
                              name="card_number"
                              id="card_number"
                              class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                              placeholder="Enter your card number"
                              autocomplete="card_number"
                              :class="
                                   form.errors?.errors?.card_number != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                        : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              "
                         />
                         <has-error-form
                              :form="form"
                              :field="'card_number'"
                         ></has-error-form>
                    </div>
                    <div
                         v-if="card_selected?.paypage?.card_expiration == null"
                         class="mb-2"
                    >
                         <label
                              for="card_expiration"
                              class="block mb-2 text-sm font-medium text-shade-2"
                         >
                              Card Expiration Date
                         </label>
                         <input
                              v-model="form.card_expiration"
                              type="text"
                              name="card_expiration"
                              id="card_expiration"
                              class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                              placeholder="Enter your card expiration date"
                              autocomplete="card_expiration"
                              :class="
                                   form.errors?.errors?.card_number != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                        : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              "
                         />
                         <has-error-form
                              :form="form"
                              :field="'card_expiration'"
                         ></has-error-form>
                    </div>
                    <div class="flex items-start gap-3 mb-2">
                         <div class="flex-1">
                              <label
                                   for="province_id"
                                   class="block mb-2 text-sm font-medium text-shade-2"
                                   >State</label
                              >
                              <searchableSelect
                                   :clearFormErrorFunction="clearFormError"
                                   :form="form"
                                   :options="state"
                                   v-model:selectedOption="stateVal"
                                   :optionLabel="'province_name'"
                                   :optionValue="'id'"
                                   :keyValue="'billing.' + 'state'"
                                   :placeholder="'Select state'"
                              />
                              <has-error-form
                                   :form="form"
                                   :field="'billing.state'"
                              ></has-error-form>
                         </div>
                         <div class="flex-1">
                              <label
                                   for="city_id"
                                   class="block mb-2 text-sm font-medium text-shade-2"
                                   >City</label
                              >
                              <searchableSelect
                                   :clearFormErrorFunction="clearFormError"
                                   :form="form"
                                   :options="city"
                                   v-model:selectedOption="cityVal"
                                   :optionLabel="'city_name'"
                                   :optionValue="'city_name'"
                                   :keyValue="'billing.' + 'city'"
                                   :placeholder="'Select city'"
                              />
                              <has-error-form
                                   :form="form"
                                   :field="'billing.city'"
                              ></has-error-form>
                         </div>
                         <div class="flex-1">
                              <label
                                   for="zip_code"
                                   class="block mb-2 text-sm font-medium text-shade-2"
                                   >ZIP code</label
                              >
                              <searchableSelect
                                   :clearFormErrorFunction="clearFormError"
                                   :form="form"
                                   :options="zipCode"
                                   v-model:selectedOption="form.billing.zipCode"
                                   :optionLabel="'zip_code'"
                                   :optionValue="'zip_code'"
                                   :keyValue="'billing.' + 'zipCode'"
                                   :placeholder="'Zip Code'"
                              />
                              <has-error-form
                                   :form="form"
                                   :field="'billing.zipCode'"
                              ></has-error-form>
                         </div>
                    </div>
                    <div class="mb-2">
                         <label
                              for="address_1"
                              class="block mb-2 text-sm font-medium text-shade-2"
                              >Address line</label
                         >
                         <input
                              v-model="form.billing.address_1"
                              type="text"
                              name="address_1"
                              id="address_1"
                              class="bg-white border border-shade-10 text-shade-2 text-sm rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 font-normal"
                              placeholder="Enter your address"
                              autocomplete="address_1"
                              :class="
                                   form.errors?.errors?.address_1 != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                        : 'border-shade-10 focus:ring-background-2 focus:border-background-2'
                              "
                         />
                         <has-error-form
                              :form="form"
                              :field="'billing'"
                         ></has-error-form>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";
import { cardStore } from "store/cards.js";
import searchableSelect from "components/searchableselect/index.vue";
import HasErrorForm from "components/haserror/index.vue";
import { utilitiesStore } from "store/utilities.js";
import {
     getCity as getCityAPI,
     getZipCode as getZipCodeAPI
} from "api/utility.js";
import _ from "underscore";
import { baseUrl } from "config/services.js";
import eventBus from "plugins/event.js";

export default {
     props: [
          "form",
          "onUpdateID",
          "clearFormError",
          "updateForm",
          "card_selected",
          "hideForm"
     ],
     components: {
          HasErrorForm,
          searchableSelect
     },
     setup(props) {
          const loading = ref(false);
          const city = ref([]);
          const zipCode = ref([]);
          const stateVal = ref("");
          const cityVal = ref("");

          const useCardStore = cardStore();
          const useUtilitiesStore = utilitiesStore();

          const cardDetails = computed(() => {
               return useCardStore.cardsRedeem ?? [];
          });

          const state = computed(() => {
               return useUtilitiesStore.state;
          });

          async function getCity(id) {
               await getCityAPI({ province_id: id }).then((res) => {
                    city.value = res;
               });
          }

          async function getZipCode(id, cityName) {
               const filter = _.filter(city.value, function (params) {
                    return params["city_name"] == cityName;
               });

               if (filter[0]?.county) {
                    const params = {
                         city_name: cityName,
                         county: filter[0]?.county,
                         province_id: id
                    };

                    await getZipCodeAPI(params).then((res) => {
                         zipCode.value = res;
                    });
               }
          }

          const openPaymentCheckout = () => {
               eventBus.emit("open:add_bank_payment", "credit card");
          };

          const closePaymentCheckout = () => {
               eventBus.emit("close:add_bank_payment");
          };

          onMounted(async () => {
               loading.value = true;
               await useCardStore.getCardsRedeem();
               loading.value = false;
          });

          watch(stateVal, async (newVal) => {
               if (newVal) {
                    getCity(newVal);

                    const filter = _.filter(state.value, (params) => {
                         return params["id"] == newVal;
                    });

                    props.updateForm(
                         "billing.state",
                         filter[0]["province_name"]
                    );

                    cityVal.value = "";
                    props.updateForm("billing.city", "");
                    zipCode.value = [];
                    props.updateForm("billing.zipCode", "");
               } else {
                    cityVal.value = "";
                    props.updateForm("billing.state", "");
                    props.updateForm("billing.city", "");
                    zipCode.value = [];
                    props.updateForm("billing.zipCode", "");
                    city.value = [];
               }
          });

          watch(cityVal, async (newVal) => {
               if (newVal) {
                    if (stateVal.value != null) {
                         getZipCode(stateVal.value, newVal);
                         props.updateForm("billing.city", newVal);
                         props.updateForm("billing.zipCode", "");
                    }
               } else {
                    if (stateVal.value != null) {
                         props.updateForm("billing.city", "");
                         props.updateForm("billing.zipCode", "");
                         zipCode.value = [];
                    }
               }
          });

          return {
               cardDetails,
               loading,
               state,
               city,
               zipCode,
               stateVal,
               cityVal,
               baseUrl,
               openPaymentCheckout,
               closePaymentCheckout
          };
     }
};
</script>
