<template>
     <InertiaHead title="About Us"></InertiaHead>
     <div class="home-wrapper mt-0">
          <div
               class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"
          ></div>
          <div
               class="banner flex bg-no-repeat w-full font-helveticaLight flex-col justify-center items-center relative"
          >
               <div class="text-center">
                    <div class="pt-16 md:pt-20 xl:pt-24">
                         <h1
                              class="text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl uppercase"
                         >
                              About Us
                         </h1>
                    </div>
               </div>
               <div class="w-full flex justify-center items-center pb-20">
                    <div
                         class="py-8 sm:py-8 md:py-10 lg:py-14 xl:py-18 px-4 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-64"
                    >
                         <div
                              class="bg-white rounded-xl py-6 sm:py-12 md:py-20 lg:py-28 px-6 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-48 shadow-xl"
                         >
                              <p
                                   class="mb-0 text-sm sm:text-base lg:text-lg text-justify"
                              >
                                   <b
                                        >Welcome to Moozi – Where Gaming Meets
                                        Social!</b
                                   >
                              </p>
                              <br />
                              <p
                                   class="mb-0 text-sm sm:text-base lg:text-lg text-justify"
                              >
                                   At Moozi, we believe in the power of play,
                                   connection, and community. Born from the idea
                                   that gaming is more than just a solitary
                                   experience, Moozi is a vibrant and dynamic
                                   social gaming platform that brings players
                                   together for shared adventures, laughter, and
                                   camaraderie.
                              </p>
                              <br />
                              <p
                                   class="mb-0 text-sm sm:text-base lg:text-lg text-justify"
                              >
                                   <b>Our Story:</b> Moozi was founded by a
                                   group of passionate gamers who shared a
                                   common vision – to create a space where
                                   gaming transcends beyond pixels and screens,
                                   fostering genuine connections among players
                                   from all walks of life. Inspired by the
                                   diverse and inclusive nature of the gaming
                                   community, Moozi was born to celebrate the
                                   joy of playing together.
                              </p>
                              <br />
                              <p
                                   class="mb-0 text-sm sm:text-base lg:text-lg text-justify"
                              >
                                   <b>Mission:</b> At Moozi, our mission is to
                                   break down barriers and build bridges through
                                   the universal language of gaming. We strive
                                   to create an immersive and entertaining
                                   environment where players can forge lasting
                                   friendships, embark on epic quests, and share
                                   unforgettable moments. Whether you're a
                                   casual gamer or a seasoned pro, Moozi is your
                                   home for gaming and socializing.
                              </p>

                              <br /><br />
                              <p
                                   class="mb-3 font-bold text-base sm:text-lg lg:text-xl"
                              >
                                   What Sets Moozi Apart:
                              </p>
                              <br />
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <b><span>1. </span>Community First:</b> Moozi
                                   is not just a gaming platform; it's a
                                   thriving community of like-minded individuals
                                   who share a passion for gaming.
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <b
                                        ><span>2. </span>Diverse Gaming
                                        Experiences:</b
                                   >
                                   Moozi offers a wide array of games to suit
                                   every taste. Discover new titles, with our
                                   ever-changing platform.
                              </p>
                              <p class="mb-3 text-sm sm:text-base lg:text-lg">
                                   <b
                                        ><span>3. </span>Safe and Inclusive
                                        Environment</b
                                   >
                                   We are committed to creating a safe and
                                   inclusive space for all gamers. Moozi values
                                   diversity, respect, and fair play.
                              </p>

                              <br /><br />

                              <p
                                   class="mb-0 text-sm sm:text-base lg:text-lg text-justify"
                              >
                                   <b>Join the Moozi Community Today:</b>
                                   Whether you're a solo player seeking
                                   companionship, a competitive gamer hungry for
                                   challenges, or someone looking to explore new
                                   horizons in the gaming world, Moozi welcomes
                                   you with open arms. Join us in creating a
                                   global gaming community where every player
                                   has a place and every game is an opportunity
                                   for shared joy.
                              </p>

                              <br /><br />
                              <p
                                   class="mb-0 text-sm sm:text-base lg:text-lg text-justify"
                              >
                                   Embark on your Moozi adventure today.
                              </p>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { useScroll } from "composables/use-scroll.js";
import { onMounted } from "vue";

export default {
     components: {
          InertiaHead
     },
     setup() {
          const { scrollTop } = useScroll();

          onMounted(async () => {
               scrollTop();
          });

          return {};
     }
};
</script>

<style></style>
