<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isGameFullScreen"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 w-full max-h-full transform ease-in-out transition-all duration-300 self-center m-auto inset-0 font-helveticaLight flex justify-center items-center"
          >
               <div
                    class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-4xl max-h-full m-auto"
               >
                    <div
                         class="relative bg-gradient-benefits rounded-xl shadow-xl"
                    >
                         <button
                              @click="closeModal()"
                              type="button"
                              class="z-10 close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center invert bg-moozi-2"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div>
                              <img
                                   :src="image"
                                   alt=""
                                   class="rounded-xl lg:rounded-2xl w-full"
                              />
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isGameFullScreen"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.7)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
     setup() {
          const isGameFullScreen = ref(false);
          const image = ref(false);

          const useUtilitiesStore = utilitiesStore();

          const closeModal = () => {
               isGameFullScreen.value = false;
               data.value = null;
               onRedirect.value = null;
               useUtilitiesStore.enableScroll();
          };

          onMounted(async () => {
               eventBus.on("open:gamefullscreen", (data) => {
                    image.value = data;
                    useUtilitiesStore.disableScroll();
                    isGameFullScreen.value = true;
               });

               eventBus.on("close:gamefullscreen", () => {
                    useUtilitiesStore.enableScroll();
                    isGameFullScreen.value = false;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:gamefullscreen");
               eventBus.off("close:gamefullscreen");
          });

          return {
               isGameFullScreen,
               closeModal,
               image,
               baseUrl
          };
     }
};
</script>
