import { defineStore } from "pinia";
import { getCoins as getCoinsAPI } from "api/utility.js";
import { worldpayStatus as worldpayStatusAPI } from "api/transaction.js";
import { limitReach as limitReachAPI } from "api/auth.js";
import { authStore } from "store/auth.js";

export const coinsStore = defineStore("coins", {
     state: () => {
          return {
               coins: null,
               error_msg: null,
               worldpayStatus: null,
               limitReachValue: null
          };
     },
     actions: {
          getWorldPay() {
               if (this.worldpayStatus != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    worldpayStatusAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.worldpayStatus = response.status;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.worldpayStatus = false;
                                   }
                              });
                              // console.error('Error to load coins data!', error);
                              // errorToast('Error to load data!', "top-right");
                              reject(error);
                         });
               });
          },
          getCoins(force = false) {
               if (!force) {
                    if (this.coins != null) {
                         return;
                    }
               }
               return new Promise((resolve, reject) => {
                    getCoinsAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.coins = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (
                                        error.response.status == 503 &&
                                        ["time", "days"].includes(
                                             error.response.data?.type
                                        )
                                   ) {
                                        const useAuthStore = authStore();

                                        useAuthStore.setSelfExclusionTimer(
                                             error.response.data
                                        );
                                        return;
                                   }

                                   if (error.response) {
                                        state.coins = error.response.data;
                                   }
                              });
                              // console.error('Error to load coins data!', error);
                              // errorToast('Error to load data!', "top-right");
                              reject(error);
                         });
               });
          },
          getLimitReach(force = false) {
               if (!force) {
                    if (this.limitReachValue != null) {
                         return;
                    }
               }
               return new Promise((resolve, reject) => {
                    limitReachAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response.status) {
                                        state.limitReachValue = Number(
                                             response.purchase_amount ?? 0
                                        );
                                   } else {
                                        state.limitReachValue = null;
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   state.limitReachValue = null;
                              });
                              reject(error);
                         });
               });
          }
     }
});
