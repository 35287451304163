export const useGTag = () => {
     const item_list_id = "gold_coin_package";
     const item_list_name = "Gold Coin Package";
     const currency = "USD";

     // Push view_item_list event to the dataLayer
     const gTagViewItemList = (items) => {
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "view_item_list",
               ecommerce: {
                    item_list_id: item_list_id,
                    item_list_name: item_list_name,
                    items: items
               }
          });
     };

     // Push select_item event to the dataLayer
     const gTagSelectItemList = (item) => {
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "select_item",
               ecommerce: {
                    item_list_id: item_list_id,
                    item_list_name: item_list_name,
                    items: [item]
               }
          });
     };

     // Push begin_checkout event to the dataLayer
     const gTagBeginCheckout = (value, item) => {
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "begin_checkout",
               ecommerce: {
                    currency: currency,
                    value: value,
                    items: [item]
               }
          });
     };

     // Push add_payment_info event to the dataLayer
     const gTagAddPaymentInfo = (value, payment_type, item) => {
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "add_payment_info",
               ecommerce: {
                    currency: currency,
                    value: value,
                    payment_type: payment_type,
                    items: [item]
               }
          });
     };

     // Push purchase event to the dataLayer
     const gTagPurchase = (transaction_id, value, item) => {
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({ ecommerce: null });
          dataLayer.push({
               event: "purchase",
               ecommerce: {
                    transaction_id: transaction_id,
                    currency: currency,
                    value: value,
                    items: [item]
               }
          });
          fbq("track", "Purchase", { currency: currency, value: value });
          ttd_dom_ready(function () {
               if (typeof TTDUniversalPixelApi === "function") {
                    var universalPixelApi = new TTDUniversalPixelApi();
                    universalPixelApi.init(
                         "nqpc8db",
                         ["qage55c"],
                         "https://insight.adsrvr.org/track/up",
                         {
                              v: value,
                              vf: currency
                         }
                    );
               }
          });
     };

     return {
          gTagViewItemList,
          gTagSelectItemList,
          gTagBeginCheckout,
          gTagAddPaymentInfo,
          gTagPurchase
     };
};
