import { isLogged } from "utils/auth.js";
import { authStore } from "store/auth.js";
import { useRouter } from "vue-router";

export default async (context) => {
     const router = useRouter();
     const useAuthStore = authStore();
     let to = context.to;
     // useAuthStore.getUser();
     const token = isLogged();

     if (token && to.query.auth_key) {
          // console.log('hasToken');
          setTimeout(() => {
               return router.push({ name: "lobby" });
          }, "1000");
     }
     if (to.query.auth_key && !token) {
          const params = {};

          params["auth_key"] = to.query.auth_key;
          params["new"] = to.query.new;
          if (to.query.social) {
               params["social"] = to.query.social;
          }

          useAuthStore
               .login(params)
               .then(() => {
                    useAuthStore.getUser().then(async () => {
                         setTimeout(() => {
                              window.location.reload();
                         }, 1000);
                    });
               })
               .catch(() => {
                    router.push({ name: "login" });
               });
     }

     context.next();
};
