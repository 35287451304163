<template>
     <InertiaHead title="Home"></InertiaHead>
     <div class="lobby-container">
          <Carousel v-if="isSelfExclusion == null" />
          <Games v-if="isSelfExclusion == null" />
          <Promotions v-if="isSelfExclusion == null" />
          <Benefits v-if="isSelfExclusion == null" />
          <Providers v-if="isSelfExclusion == null" />
          <GamePreview v-if="isSelfExclusion == null" />
          <div
               v-else-if="isSelfExclusion != null"
               class="min-h-screen -mt-16 flex justify-center items-center"
          >
               <SelfExclusionAlert />
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { verifyAccount } from "api/verify.js";
import { authStore } from "store/auth.js";
import { computed, onMounted } from "vue";
import { useToaster } from "composables/use-toaster.js";
import { useRouter } from "vue-router";
import { promoStore } from "store/promotion";
import { gamesStore } from "store/games";
import { useScroll } from "composables/use-scroll.js";
import Carousel from "./components/carousel/index.vue";
import Games from "./components/games/index.vue";
import Promotions from "./components/promotions/index.vue";
import Benefits from "./components/benefits/index.vue";
import Providers from "./components/providers/index.vue";
import GamePreview from "components/modals/gamePreview.vue";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";

export default {
     components: {
          InertiaHead,
          Carousel,
          Games,
          Promotions,
          Providers,
          Benefits,
          GamePreview,
          SelfExclusionAlert
     },
     setup() {
          const router = useRouter();

          const { scrollTop } = useScroll();

          const useGamesStore = gamesStore();
          const usePromoStore = promoStore();
          const useAuthStore = authStore();

          const { successToast, errorToast } = useToaster();
          const promoData = computed(() => {
               return usePromoStore.promo || null;
          });

          const isSelfExclusion = computed(() => {
               return useAuthStore.isSelfExclusion;
          });

          onMounted(async () => {
               // if (route?.redirectedFrom?.path == '/games' || route?.redirectedFrom?.path == '/') {
               //           window.location.href = '/lobby'
               //           return;
               //      }
               // window.location.href = '/lobby'
               scrollTop();
               await usePromoStore.getPromotion({ published_at: "promotion" });
               await usePromoStore.getBanner();

               await useGamesStore.getAllProvider();
               if (isUser.value) {
                    await useGamesStore.getHeart();
               }

               if (
                    new URL(location.href).searchParams.get(
                         "account_activation"
                    )
               ) {
                    await verifyAccount(
                         new URL(location.href).searchParams.get(
                              "account_activation"
                         )
                    ).then(async (data) => {
                         if (data.status) {
                              await useAuthStore.getUser(true);
                              successToast(data.message, "top-right");
                              router.push({ path: "/" });
                         } else {
                              errorToast(data.message, "top-right");
                              router.push({ path: "/" });
                         }
                    });
               }
               // setTimeout(() => {
               //      history.replaceState(null, "", "/lobby");
               // }, 1000);
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          return {
               isUser,
               promoData,
               isSelfExclusion
               // viewportHeight,
               // clientHeight
          };
     }
};
</script>
