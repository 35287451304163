<template>
     <div class="flex h-full flex-col justify-between pb-4">
          <div class="w-full mb-7">
               <p
                    class="text-moozi-1 font-roboto text-center w-full px-1 md:px-8 py-2 text-sm xs:text-base font-bold"
               >
                    Coins Balance
               </p>
               <div
                    class="w-full flex flex-col sm:flex-row justify-center items-center sm:gap-2 lg:gap-4"
               >
                    <div class="w-full sm:w-6/12 flex md:justify-end">
                         <div
                              class="bg-background-3 !text-white flex flex-row w-full md:w-max items-center mt-2 rounded-full"
                         >
                              <img
                                   class="cursor-pointer w-10"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/header/GC.svg'
                                   "
                                   alt=""
                              />
                              <p
                                   class="font-roboto font-bold tracking-wide text-center text-sm sm:text-base pl-3 pr-4 w-full"
                              >
                                   {{ money(gold_balance) }}
                                   GC
                              </p>
                         </div>
                    </div>
                    <div class="w-full sm:w-6/12 flex md:justify-start">
                         <div
                              class="bg-background-3 !text-white flex flex-row w-full md:w-max items-center mt-2 rounded-full"
                         >
                              <img
                                   class="cursor-pointer w-10"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/header/SC.svg'
                                   "
                                   alt=""
                              />
                              <p
                                   class="font-roboto font-bold tracking-wide text-center text-sm sm:text-base pl-3 pr-4 w-full"
                              >
                                   {{ money(silver_balance) }}
                                   SC
                              </p>
                         </div>
                    </div>
               </div>
          </div>
          <div
               v-if="tableLoading"
               class="w-full flex items-center justify-center"
          >
               <!-- <loading-small :isLoading="tableLoading" /> -->
               <svg
                    aria-hidden="true"
                    class="w-8 h-8 text-primal-black animate-spin fill-primal-yellow"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                         fill="currentColor"
                    ></path>
                    <path
                         d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                         fill="currentFill"
                    ></path>
               </svg>
          </div>
          <div
               v-if="!tableLoading"
               class="h-full py-1 xs:py-0 custom-flex-style overflow-x-auto hidden lg:flex flex-col"
          >
               <table
                    class="font-roboto border-collapse border-spacing-4 table-auto w-full whitespace-nowrap"
               >
                    <thead>
                         <tr
                              class="bg-moozi-1 text-white text-xs lg:text-sm font-bold"
                         >
                              <th class="w-2/12 text-center py-3">Date</th>

                              <th class="w-3/12 text-center py-3">
                                   Transaction
                              </th>
                              <th class="text-center py-3">Amount</th>
                              <th class="text-center py-3">Status</th>
                         </tr>
                    </thead>
                    <tbody
                         v-if="transaction_data?.length != 0"
                         class="text-xs lg:text-sm"
                    >
                         <tr
                              v-for="(data, index) in transaction_data"
                              class="border-2 rounded-md text-xs lg:text-sm tracking-wide"
                              :class="
                                   index % 2 == 1
                                        ? 'border-gray-180'
                                        : 'border-none'
                              "
                              :key="index"
                         >
                              <td
                                   class="text-center"
                                   :class="{
                                        'pl-2': index % 2 == 1,
                                        'pt-2': !(index % 2 == 1)
                                   }"
                              >
                                   {{
                                        data.created_at
                                             ? date8(new Date(data.created_at))
                                             : ""
                                   }}
                              </td>

                              <td
                                   class="text-center capitalize"
                                   :class="index % 2 == 1 ? '' : 'hidden'"
                              >
                                   {{ transactionLabel(data) }}
                              </td>
                              <td :class="index % 2 == 1 ? '' : 'hidden'">
                                   <div
                                        class="flex item-center justify-center font-bold text-right space-x-5"
                                   >
                                        <div
                                             class="flex items-center space-x-0.5"
                                             :class="show1stCol(data)"
                                        >
                                             <img
                                                  :class="
                                                       showGoldIcon(data)
                                                            ? 'block h-4'
                                                            : 'hidden'
                                                  "
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/GC.svg'
                                                  "
                                                  alt=""
                                             />
                                             <div>
                                                  {{ show1stAmount(data) }}
                                             </div>
                                        </div>
                                        <div
                                             class="flex items-center justify-end space-x-0.5"
                                             :class="show2ndCol(data)"
                                        >
                                             <img
                                                  :class="
                                                       showSilverIcon(data)
                                                            ? 'block h-4'
                                                            : 'hidden'
                                                  "
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/SC.svg'
                                                  "
                                                  alt=""
                                             />
                                             <div>
                                                  {{ show2ndAmount(data) }}
                                             </div>
                                        </div>
                                   </div>
                              </td>
                              <td :class="index % 2 == 1 ? '' : 'hidden'">
                                   <div
                                        class="py-2 flex items-center justify-center"
                                   >
                                        <div
                                             :class="{
                                                  'bg-secondary-6':
                                                       data.payment_status == 0,
                                                  'bg-primal-green':
                                                       data.payment_status == 1,
                                                  'bg-primal-red':
                                                       data.payment_status == 2
                                             }"
                                             class="rounded-full w-2 h-2 mr-2"
                                        ></div>
                                        <span>
                                             {{
                                                  data.payment_status == 0
                                                       ? "Pending"
                                                       : data.payment_status ==
                                                           1
                                                         ? "Success"
                                                         : "Failed"
                                             }}
                                        </span>
                                   </div>
                              </td>
                         </tr>
                    </tbody>
               </table>
               <div
                    v-if="transaction_data?.length == 0"
                    class="w-full h-full flex justify-center items-center text-moozi-1 font-medium tracking-wide"
               >
                    No transaction found.
               </div>
          </div>
          <div
               v-if="!tableLoading"
               class="py-1 px-0 xs:py-0 custom-flex-style billing overflow-x-auto block lg:hidden"
               :style="tableLoading ? 'height:90%' : 'max-height:90%'"
          >
               <div
                    v-if="transaction_data.length == 0"
                    class="w-full h-full flex justify-center items-center text-moozi-1 font-medium tracking-wide"
               >
                    No transaction found.
               </div>
               <div v-else>
                    <div v-if="transaction_mobile">
                         <div
                              v-for="(data, index) in Object.keys(
                                   transaction_mobile
                              )"
                              :key="index"
                         >
                              <div class="bg-moozi-1">
                                   <div
                                        class="px-4 py-3 font-bold text-white text-sm"
                                   >
                                        {{ data }}
                                   </div>
                                   <div
                                        v-for="(
                                             item, key
                                        ) in transaction_mobile[data]"
                                        :key="key"
                                   >
                                        <div
                                             class="px-4 py-2 bg-white !border-b-background-5"
                                             style="border-bottom: 1px solid"
                                        >
                                             <div
                                                  class="text-gray-10 font-roboto flex flex-row justify-between text-xs"
                                             >
                                                  <div>
                                                       {{ item.time }}
                                                  </div>
                                                  <div
                                                       class="px-1 lg:pl-6 xl:pl-8 flex items-center"
                                                  >
                                                       <div
                                                            :class="
                                                                 item.payment_status ==
                                                                 0
                                                                      ? 'bg-primal-yellow'
                                                                      : item.payment_status ==
                                                                          1
                                                                        ? 'bg-primal-green'
                                                                        : 'bg-primal-red'
                                                            "
                                                            class="rounded-full w-2 h-2 mr-2"
                                                       ></div>
                                                       <span>{{
                                                            item.payment_status ==
                                                            0
                                                                 ? "Pending"
                                                                 : item.payment_status ==
                                                                     1
                                                                   ? "Success"
                                                                   : "Failed"
                                                       }}</span>
                                                  </div>
                                             </div>
                                             <div
                                                  class="flex flex-row justify-between text-sm"
                                             >
                                                  <div
                                                       class="font-bold capitalize"
                                                  >
                                                       {{
                                                            transactionLabel(
                                                                 item
                                                            )
                                                       }}
                                                  </div>
                                                  <div
                                                       class="flex flex-row gap-5 font-bold"
                                                  >
                                                       <div
                                                            class="flex items-center space-x-0.5 w-full"
                                                            :class="
                                                                 show1stCol(
                                                                      item
                                                                 )
                                                            "
                                                       >
                                                            <img
                                                                 :class="
                                                                      showGoldIcon(
                                                                           item
                                                                      )
                                                                           ? 'block h-4'
                                                                           : 'hidden'
                                                                 "
                                                                 :src="
                                                                      baseUrl.img +
                                                                      '/assets/img/header/GC.svg'
                                                                 "
                                                                 alt=""
                                                            />
                                                            <div>
                                                                 {{
                                                                      show1stAmount(
                                                                           item
                                                                      )
                                                                 }}
                                                            </div>
                                                       </div>
                                                       <div
                                                            class="flex items-center justify-end space-x-0.5"
                                                            :class="
                                                                 show2ndCol(
                                                                      item
                                                                 )
                                                            "
                                                       >
                                                            <img
                                                                 :class="
                                                                      showSilverIcon(
                                                                           item
                                                                      )
                                                                           ? 'block h-4'
                                                                           : 'hidden'
                                                                 "
                                                                 :src="
                                                                      baseUrl.img +
                                                                      '/assets/img/header/SC.svg'
                                                                 "
                                                                 alt=""
                                                            />
                                                            <div>
                                                                 {{
                                                                      show2ndAmount(
                                                                           item
                                                                      )
                                                                 }}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div
               class="mt-12 xl:mt-4 flex justify-center px-5 md:px-7 lg:px-10 2xl:px-36"
          >
               <div
                    v-if="pagination.totalPage"
                    class="flex w-full justify-center items-center gap-2 md:gap-7 mt-5"
               >
                    <button
                         class="prev p-3 lg:p-4 rounded-full text-moozi-1"
                         :class="
                              pagination.currentPage > 2
                                   ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer bg-secondary-3'
                                   : 'cursor-not-allowed bg-gray-190'
                         "
                         @click="onFirstPage"
                         :disabled="pagination.currentPage < 2"
                    >
                         <svg
                              class="w-3 h-3"
                              :class="
                                   pagination.currentPage > 2
                                        ? ''
                                        : 'opacity-20'
                              "
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M8 2.00391L2 8.00391L8 14.0039"
                                   stroke="black"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                              <path
                                   d="M13 2.00391L7 8.00391L13 14.0039"
                                   stroke="black"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                    </button>
                    <button
                         class="prev p-3 lg:p-4 rounded-full text-moozi-1"
                         :class="
                              pagination.currentPage != 1
                                   ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3'
                                   : 'cursor-not-allowed bg-gray-190'
                         "
                         @click="onPrevious"
                         :disabled="pagination.currentPage == 1"
                    >
                         <svg
                              class="w-3 h-3"
                              :class="
                                   pagination.currentPage != 1
                                        ? ''
                                        : 'opacity-20'
                              "
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M11 1.99805L5 7.99805L11 13.998"
                                   stroke="black"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                    </button>
                    <div
                         class="font-medium p-3 lg:p-4 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                         :class="
                              pagination.currentPage == 1
                                   ? 'text-moozi-8 underline underline-offset-4'
                                   : 'text-primary-1'
                         "
                         @click="onFirstBtn"
                    >
                         <span class="text-lg leading-5 text-center w-4 h-4">
                              {{
                                   pagination.currentPage == 1
                                        ? 1
                                        : (pagination.currentPage ==
                                                 pagination.totalPage &&
                                                 pagination.totalPage < 2) ||
                                            (pagination.currentPage ==
                                                 pagination.totalPage &&
                                                 pagination.currentPage != 2)
                                          ? pagination.currentPage - 2
                                          : pagination.currentPage - 1
                              }}
                         </span>
                    </div>
                    <div
                         v-if="pagination.totalPage != 1"
                         class="font-medium p-3 lg:p-4 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                         :class="
                              (pagination.currentPage != 1 &&
                                   pagination.currentPage !=
                                        pagination.totalPage) ||
                              (pagination.currentPage == 2 &&
                                   pagination.currentPage ==
                                        pagination.totalPage)
                                   ? 'text-secondary-3 underline underline-offset-4'
                                   : 'text-primary-1'
                         "
                         @click="onSecondBtn"
                    >
                         <span class="text-lg leading-5 text-center w-4 h-4">
                              {{
                                   pagination.currentPage == 1
                                        ? pagination.currentPage + 1
                                        : (pagination.currentPage ==
                                                 pagination.totalPage &&
                                                 pagination.totalPage < 2) ||
                                            (pagination.currentPage ==
                                                 pagination.totalPage &&
                                                 pagination.currentPage != 2)
                                          ? pagination.currentPage - 1
                                          : pagination.currentPage
                              }}
                         </span>
                    </div>
                    <div
                         v-if="
                              pagination.totalPage != 1 &&
                              pagination.totalPage != 2
                         "
                         class="font-medium p-3 lg:p-4 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                         :class="
                              pagination.currentPage == 1
                                   ? 'text-primary-1'
                                   : pagination.currentPage ==
                                       pagination.totalPage
                                     ? 'text-secondary-3 underline underline-offset-4'
                                     : 'text-primary-1'
                         "
                         @click="onThirdBtn"
                    >
                         <span
                              class="text-lg leading-5 text-center w-4 h-4 cursor-pointer"
                         >
                              {{
                                   pagination.currentPage == 1
                                        ? pagination.currentPage + 2
                                        : pagination.currentPage ==
                                            pagination.totalPage
                                          ? pagination.currentPage
                                          : pagination.currentPage + 1
                              }}
                         </span>
                    </div>
                    <button
                         class="p-3 lg:p-4 rounded-full text-primary-1"
                         :class="
                              pagination.currentPage != pagination.totalPage
                                   ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer bg-secondary-3'
                                   : 'cursor-not-allowed bg-gray-190'
                         "
                         @click="onNext"
                         :disabled="
                              pagination.currentPage == pagination.totalPage
                         "
                    >
                         <svg
                              class="w-3 h-3"
                              :class="
                                   pagination.currentPage !=
                                   pagination.totalPage
                                        ? ''
                                        : 'opacity-20'
                              "
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M5 14L11 8L5 2"
                                   stroke="#422C18"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                    </button>
                    <button
                         class="p-3 lg:p-4 rounded-full text-primary-1"
                         :class="
                              pagination.currentPage != pagination.totalPage
                                   ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer bg-secondary-3'
                                   : 'cursor-not-allowed bg-gray-190'
                         "
                         @click="onLastPage"
                         :disabled="
                              pagination.currentPage == pagination.totalPage
                         "
                    >
                         <svg
                              class="w-3 h-3"
                              :class="
                                   pagination.currentPage !=
                                   pagination.totalPage
                                        ? ''
                                        : 'opacity-20'
                              "
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M8 14L14 8L8 2"
                                   stroke="#422C18"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                              <path
                                   d="M3 14L9 8L3 2"
                                   stroke="#422C18"
                                   stroke-width="2"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                              />
                         </svg>
                    </button>
               </div>
          </div>
     </div>
</template>
<script>
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";
import { onMounted, ref, computed } from "vue";
import { watch, toRefs } from "vue";
import _ from "underscore";
import { authStore } from "@/store/auth";

export default {
     props: [
          "tableLoading",
          "transaction_data",
          "transaction",
          "pagination",
          "onFirstPage",
          "onPrevious",
          "onFirstBtn",
          "onSecondBtn",
          "onThirdBtn",
          "onNext",
          "onLastPage"
     ],
     setup(props) {
          const { date8, date6, time, money, eighteenYearsAgo } =
               useFormatter();

          const useAuthStore = authStore();

          const { transaction_data } = toRefs(props);

          const transaction_mobile = ref(null);

          const silver_balance = computed(() => {
               return (
                    parseFloat(useAuthStore.silver_balance) +
                    parseFloat(useAuthStore.silver_balance_redeem)
               );
          });

          const gold_balance = computed(() => {
               return useAuthStore.gold_balance;
          });

          const parseToMobile = (newVal) => {
               const filter = _.filter(newVal, function (params) {
                    return params != "";
               });

               const map = _.map(filter, function (params) {
                    return {
                         ...params,
                         date: date6(new Date(params["created_at"])),
                         time: time(new Date(params["created_at"]))
                    };
               });

               const group = _.groupBy(map, "date");

               transaction_mobile.value = group;
          };

          const transactionLabel = (data) => {
               switch (data.transaction_type) {
                    case 1:
                         return data.provider == "MANUAL"
                              ? "Manual Deposit"
                              : "Purchase";
                    case 2:
                         return "Withdraw";
                    case 3:
                         return data.notes.toLowerCase();
                    case 4:
                         return "Deposit Adjustment";
                    case 5:
                         return "Expired SCC";
                    case 6:
                         return "Link Card";
                    case 7:
                         return "Refund";
               }
          };

          const show1stCol = (data) => {
               if (data.transaction_type == 5) {
                    return "hidden";
               } else if (
                    data.transaction_type == 3 &&
                    JSON.parse(data.conversion).gold_coins
               ) {
                    return "justify-end";
               }
               return "justify-center";
          };

          const show2ndCol = (data) => {
               if (
                    data.transaction_type == 5 ||
                    (data.transaction_type == 3 &&
                         JSON.parse(data.conversion).silver_coins) ||
                    (data.transaction_type == 1 &&
                         ![null, ""].includes(data.conversion))
               ) {
                    return "";
               }
               return "hidden";
          };

          const showGoldIcon = (data) => {
               if (
                    data.transaction_type == 3 ||
                    (data.transaction_type == 1 &&
                         ![null, ""].includes(data.conversion))
               ) {
                    return true;
               }
               return false;
          };

          const showSilverIcon = (data) => {
               if (
                    data.transaction_type == 5 ||
                    data.transaction_type == 3 ||
                    (data.transaction_type == 1 &&
                         ![null, ""].includes(data.conversion))
               ) {
                    return true;
               }
               return false;
          };

          const show1stAmount = (data) => {
               switch (data.transaction_type) {
                    case 1:
                         if (![null, ""].includes(data.conversion)) {
                              return money(
                                   JSON.parse(data.conversion).gold_coins
                              );
                         } else {
                              return money(data.net_amount);
                         }
                    case 3:
                         return money(JSON.parse(data.conversion).gold_coins);
                    case 4:
                         return (
                              "- $" +
                              money(JSON.parse(data.conversion).silver_coins)
                         );
                    case 2:
                    case 5:
                    case 6:
                         return "- $" + money(data.net_amount);
                    case 7:
                         return "+ $" + money(data.net_amount);
                    default:
                         return "+" + "$" + money(data.net_amount);
               }
          };

          const show2ndAmount = (data) => {
               if (
                    data.transaction_type == 3 ||
                    (data.transaction_type == 1 &&
                         ![null, ""].includes(data.conversion))
               ) {
                    return money(JSON.parse(data.conversion).silver_coins);
               } else if (data.transaction_type == 5) {
                    return " -$" + money(data.net_amount);
               } else {
                    return "$" + money(data.net_amount);
               }
          };

          onMounted(() => {
               if (transaction_data.value) {
                    parseToMobile(transaction_data.value);
               }
          });

          watch(transaction_data, (newVal) => {
               if (newVal) {
                    if (newVal.length != 0) {
                         parseToMobile(newVal);
                    } else {
                         transaction_mobile.value = [];
                    }
               } else {
                    transaction_mobile.value = null;
               }
          });

          return {
               date8,
               money,
               eighteenYearsAgo,
               baseUrl,
               silver_balance,
               gold_balance,
               transaction_mobile,
               show1stCol,
               show2ndCol,
               showGoldIcon,
               showSilverIcon,
               show1stAmount,
               show2ndAmount,
               transactionLabel
          };
     }
};
</script>
