<template>
     <Paginate
          :data="games"
          title=""
          :onSeeMore="onSeeMore"
          :pagination="pagination"
          game_type="result"
     >
          <template v-slot:icon>
               <p class="font-roboto font-bold text-white text-sm lg:text-lg">
                    Search Results for "{{ search }}"
               </p>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Paginate from "components/games/paginate/index.vue";

export default {
     components: {
          Paginate
     },
     props: ["search"],
     setup(props) {
          const useGamesStore = gamesStore();

          const games = reactive({
               data: null
          });

          const params = reactive({});

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const gamesData = computed(() => {
               if (useGamesStore.allGames["result"]) {
                    games.data = useGamesStore.allGames["result"]["data"];
                    pagination["current"] =
                         useGamesStore.allGames["result"]["current_page"];
                    pagination["last"] =
                         useGamesStore.allGames["result"]["last_page"];
                    const temp =
                         useGamesStore.allGames["result"]["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.split("=")[1] : pagination["last"];
               } else {
                    games.data = null;
               }
               return useGamesStore.allGames["result"] || null;
          });

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               return useGamesStore.gameFilter;
          });

          const onSeeMore = async () => {
               const data = {
                    game_name: props.search,
                    game_promo: 0,
                    game_type: [],
                    provider_id: params.provider_id,
                    page: parseInt(pagination["next"]),
                    itemsPerPage: 21,
                    sortType: params.sortType
               };

               await useGamesStore.getGames(data, "result", false, true);
          };

          watch(gamesData, (newVal) => {
               if (newVal) {
                    if (newVal?.["result"]) {
                         games.data = newVal["data"];
                         pagination["current"] = newVal["current_page"];
                         pagination["last"] = newVal["last_page"];
                         const temp = newVal["next_page_url"];
                         pagination["next"] =
                              temp != null
                                   ? temp.split("=")[1]
                                   : pagination["last"];
                    }
               } else {
                    games.data = null;
               }
          });

          watch(gameFilter, () => {});

          return {
               games,
               onSeeMore,
               pagination
          };
     }
};
</script>
