<template>
     <InertiaHead title="Promotions"></InertiaHead>
     <div
          class="moozi-min-h w-full h-full relative flex justify-center bg-moozi-1"
     >
          <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               <div
                    class="w-full h-full flex flex-col justify-center items-center pb-20 lg:pb-26 pt-3 sm:pt-5 md:pt-10 lg:pt-12 xl:pt-14"
               >
                    <div
                         v-if="!loading"
                         class="w-full"
                    >
                         <div
                              v-if="promoDetail"
                              class="bg-moozi-2 rounded-xl flex flex-col"
                         >
                              <img
                                   :src="modifyImagePath(promoDetail['banner'])"
                                   alt=""
                                   class="rounded-t-xl"
                              />
                              <div
                                   class="px-5 md:px-24 lg:px-28 xl:px-32 py-8 md:py-24 lg:py-28 xl:py-32"
                              >
                                   <div
                                        v-html="promoDetail['content']"
                                        class="text-white font-helveticaLight tracking-wide text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                                   ></div>

                                   <div
                                        class="w-full flex justify-center items-center mt-8 md:mt-14 xl:mt-28"
                                   >
                                        <div
                                             @click="back"
                                             class="rounded-full font-roboto font-extrabold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer"
                                        >
                                             Back to Promotions
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div
                              v-else
                              class="bg-gradient-promo-detail rounded-xl flex flex-col w-full h-max"
                         >
                              <h1
                                   class="text-white font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                              >
                                   No Data Found
                              </h1>
                         </div>
                    </div>
                    <div
                         v-else
                         class="w-full"
                    >
                         <div
                              class="bg-gradient-promo-detail rounded-xl flex justify-center items-center w-full h-96"
                         >
                              <pulse-loader
                                   :loading="loading"
                                   color="#570106"
                                   size="20px"
                              ></pulse-loader>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { computed, onMounted, ref, watch } from "vue";
import { promoStore } from "store/promotion";
import { useRouter, useRoute } from "vue-router";
import _ from "underscore";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useScroll } from "composables/use-scroll.js";
import { usePromotion } from "composables/use-promotion.js";
import { authStore } from "@/store/auth";
import { bonusStore } from "@/store/bonus";

export default {
     components: {
          PulseLoader,
          InertiaHead
     },
     setup() {
          const router = useRouter();
          const route = useRoute();

          const useAuthStore = authStore();
          const useBonusStore = bonusStore();

          const { scrollTop } = useScroll();
          const { vipTiers, referralBonus } = usePromotion();

          const promoDetail = ref(null);
          const loading = ref(true);
          const query = ref(route.path.split("/")[3]);

          const back = () => {
               router.push({
                    path: "/promotion"
               });
          };

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const usePromoStore = promoStore();

          const promoData = computed(() => {
               if (usePromoStore.promo) {
                    const arr = query.value.split("-");
                    if (arr.length != 0) {
                         const id = parseInt(arr[arr.length - 1]);
                         const filter = _.find(
                              usePromoStore.promo,
                              function (params) {
                                   return params["id"] == id;
                              }
                         );

                         promoDetail.value = filter;
                         setTimeout(async () => {
                              console.log(
                                   filter.content.includes(
                                        "promotion-refer-friends-bonus"
                                   )
                              );

                              if (
                                   filter.content.includes(
                                        "promotion-refer-friends-bonus"
                                   )
                              ) {
                                   if (isUser.value) {
                                        await useBonusStore.getReferralStats();
                                   }
                                   referralBonus();
                              } else {
                                   vipTiers();
                              }
                         }, 1000);
                    }
                    loading.value = false;
               } else {
                    setTimeout(() => {
                         loading.value = false;
                    }, 2000);
               }

               return usePromoStore.promo || null;
          });

          const modifyImagePath = (path) => {
               if (path.includes("https")) {
                    return path;
               }
               const img = path.split(".");
               return img[0] + "-medium." + img[1];
          };

          watch(promoData, (newVal) => {
               if (newVal) {
                    const arr = query.value.split("-");
                    if (arr.length != 0) {
                         const id = parseInt(arr[arr.length - 1]);
                         const filter = _.filter(newVal, function (params) {
                              return params["id"] == id;
                         });

                         promoDetail.value = filter[0];
                    }
               }
               loading.value = false;
          });

          onMounted(() => {
               scrollTop();
               usePromoStore.getPromotion({ published_at: "promotion" });
          });

          return {
               promoDetail,
               back,
               loading,
               modifyImagePath,
               isUser
          };
     }
};
</script>
