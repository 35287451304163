import {
     getTierList as getTierListAPI,
     getUserTier as getUserTierAPI
} from "@/api/tier";
import { defineStore } from "pinia";
import _ from "underscore";
import { authStore } from "store/auth";

export const tierStore = defineStore("tier", {
     state: () => {
          return {
               tierList: null,
               userTier: null
          };
     },
     actions: {
          getTierList() {
               if (this.tierList != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getTierListAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.tierList = _.sortBy(
                                        response,
                                        "tier_points"
                                   );
                              });
                              resolve();
                         })
                         .catch((error) => {
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          },
          getUserTier() {
               if (this.userTier != null) {
                    return;
               }

               const useAuthStore = authStore();

               return new Promise((resolve, reject) => {
                    getUserTierAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   if (response?.data?.status == "error") {
                                        state.userTier = {
                                             points: "0.00",
                                             player_tier: "Bronze"
                                        };
                                   } else {
                                        state.userTier = response.data;
                                        useAuthStore.setLiveChatAttributes(
                                             "tier",
                                             state.userTier.player_tier
                                        );
                                   }
                              });
                              resolve();
                         })
                         .catch((error) => {
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              state.userTier = {
                                   points: "0.00",
                                   player_tier: "Bronze"
                              };
                              reject(error);
                         });
               });
          }
     }
});
