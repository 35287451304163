<template>
     <InertiaHead title="404"></InertiaHead>
     <!-- <div class="absolute top-0 bg-gray-100 w-full">
          <div class="h-screen flex flex-col justify-center items-center">
               <h1 class="text-8xl font-bold text-gray-800">404</h1>
               <p class="text-4xl font-medium text-gray-800">Page Not Found</p>
               <a
                    href="/"
                    class="mt-4 text-xl text-blue-600 hover:underline"
                    >Go to home page</a
               >
          </div>
     </div> -->
     <div class="bg-gradient-page h-100-vh w-full">
          <div
               class="flex items-center justify-center flex-col w-full h-full px-5 xs:px-8 sm:px-10 md:px-24 lg:px-28 xl:px-32 py-16 sm:py-20 lg:py-28 xl:py-32"
          >
               <h3
                    class="mb-5 text-white whitespace-nowrap font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase"
               >
                    404 NOT FOUND
               </h3>
               <p
                    class="text-white font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
               >
                    Mooops... This page has wandered off! <br />
                    Let's mosey back to homepage and try again
               </p>
               <a
                    href="/"
                    class="mt-8 h-10 sm:h-11 hidden md:flex flex-row items-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-8 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                    >Back to Home</a
               >
               <img
                    src="/assets/img/blade/restricted.png"
                    alt="cow"
                    class="mt-8 md:mt-10 mb-8 h-58 lg:h-auto"
               />
          </div>
     </div>
</template>

<script>
import { Head as InertiaHead } from "@inertiajs/vue3";

export default {
     components: {
          InertiaHead
     },
     setup() {
          return {};
     }
};
</script>
