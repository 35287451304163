export const useScroll = () => {
     const scrollTop = () => {
          window.scrollY = 0;
          window.scrollTo({
               top: 0,
               behavior: "smooth"
          });
     };

     return { scrollTop };
};
