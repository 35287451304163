<template>
     <div class="mb-7 lg:mb-3 w-full flex flex-row gap-0">
          <div v-if="groupData != null && !loading">
               <div
                    @click="() => onPrevious()"
                    class="w-8 lg:w-9 h-8 lg:h-9 hidden lg:flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover border border-solid border-moozi-4"
               >
                    <svg
                         width="23"
                         height="23"
                         viewBox="0 0 23 23"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                         <path
                              fill="#6A374D"
                              d="M7.58219 11.8133C7.14057 11.3634 7.14057 10.6193 7.58219 10.1693L13.3062 4.33745C13.7478 3.88752 14.4782 3.88752 14.9198 4.33745C15.3614 4.78739 15.3614 5.53152 14.9198 5.98146L9.9941 11L14.9028 16.0185C15.3444 16.4685 15.3444 17.2126 14.9028 17.6625C14.4612 18.1125 13.7308 18.1125 13.2892 17.6625L7.56524 11.8307L7.58219 11.8133Z"
                         ></path>
                    </svg>
               </div>
          </div>
          <div
               v-if="groupData != null && !loading"
               class="game-tab-wrapper flex flex-row gap-2 overflow-x-auto overflow-y-hidden h-11 lg:h-13 pb-3 mx-2 w-full"
               id="game-categories-button"
          >
               <div
                    v-if="active != null"
                    :class="
                         active != null ? 'w-max opacity-100' : 'w-0 opacity-0'
                    "
                    class="transition-all ease-in-out duration-300"
               >
                    <div
                         @click="() => onSetActive(null)"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid border-moozi-4"
                    >
                         <span class="icon-allgames text-xl lg:text-3xl"></span>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              Lobby
                         </p>
                    </div>
               </div>
               <div
                    v-for="(item, key) in groupData"
                    :key="key"
               >
                    <div
                         v-if="
                              (isUser &&
                                   ['recent', 'favorite'].includes(
                                        item.alias
                                   )) ||
                              !['recent', 'favorite'].includes(item.alias)
                         "
                         @click="onSetActive(item.alias)"
                         class="w-full h-8 lg:h-9 flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover px-3 lg:px-6 border border-solid"
                         :class="
                              active == item.alias
                                   ? 'active border-transparent'
                                   : 'border-moozi-4'
                         "
                    >
                         <div
                              class="game-category-icon"
                              v-html="decodeIcon(item.icon)"
                         ></div>
                         <p
                              class="font-roboto font-bold text-white text-xs lg:text-sm capitalize whitespace-nowrap"
                         >
                              {{ item.name.toLowerCase() }}
                         </p>
                    </div>
               </div>
          </div>
          <div
               v-else
               class="game-tab-wrapper flex flex-row gap-2 overflow-x-auto overflow-y-hidden h-11 lg:h-13 pb-3 mx-2"
          >
               <div
                    v-for="key in 12"
                    :key="key"
               >
                    <Skeletor
                         as="div"
                         class="!w-32 lg:!w-44 !h-8 lg:!h-9 !rounded-full"
                    />
               </div>
          </div>
          <div v-if="groupData != null && !loading">
               <div
                    @click="() => onNext()"
                    class="w-8 lg:w-9 h-8 lg:h-9 hidden lg:flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover border border-solid border-moozi-4"
               >
                    <svg
                         width="22"
                         height="23"
                         viewBox="0 0 22 23"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                         <path
                              fill="#6A374D"
                              d="M14.8177 10.1866C15.2593 10.6366 15.2593 11.3807 14.8177 11.8307L9.09374 17.6625C8.65213 18.1125 7.92175 18.1125 7.48014 17.6625C7.03852 17.2126 7.03852 16.4685 7.48014 16.0185L12.4058 11L7.49714 5.98146C7.05552 5.53152 7.05552 4.78739 7.49714 4.33745C7.93875 3.88752 8.66908 3.88752 9.11069 4.33745L14.8347 10.1693L14.8177 10.1866Z"
                         ></path>
                    </svg>
               </div>
          </div>
     </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import { authStore } from "store/auth.js";
import { gamesStore } from "store/games";
import { useFormatter } from "composables/use-formatter.js";

export default {
     props: ["onSetActive", "active"],
     setup() {
          const useAuthStore = authStore();
          const useGamesStore = gamesStore();

          const { decodeIcon } = useFormatter();

          const loading = ref(false);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const groupData = computed(() => {
               return useGamesStore.groups || null;
          });

          const getGameCategory = (categories, type) => {
               return type == "alias"
                    ? categories.replaceAll(" ", "-")
                    : categories;
          };

          const onPrevious = () => {
               const categories = document.querySelector(
                    "#game-categories-button"
               );
               const offset =
                    categories.scrollWidth /
                    Math.round(groupData.value.length / 1.5);
               categories.scrollBy({
                    left: -offset, // Scrolls down by 100 pixels
                    behavior: "smooth" // Smooth scrolling effect
               });
          };

          const onNext = () => {
               const categories = document.querySelector(
                    "#game-categories-button"
               );
               const offset =
                    categories.scrollWidth /
                    Math.round(groupData.value.length / 1.5);
               categories.scrollBy({
                    left: offset, // Scrolls down by 100 pixels
                    behavior: "smooth" // Smooth scrolling effect
               });
          };

          watch(groupData, () => {
               loading.value = true;
               setTimeout(() => {
                    loading.value = false;
               }, 1500);
          });

          return {
               isUser,
               loading,
               groupData,
               getGameCategory,
               decodeIcon,
               onPrevious,
               onNext
          };
     }
};
</script>
