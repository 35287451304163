<template>
     <div
          class="mt-12 md:mt-13 lg:mt-0 h-full bg-white xl:w-8/12 2xl:w-9/12 px-5 md:px-8 xl:px-10 py-5 md:py-8 xl:py-10 flex flex-col justify-start rounded-xl rounded-t-none xl:rounded-l-none xl:rounded-r-xl"
     >
          <p class="text-sm xl:text-base mb-3 text-moozi-1 font-bold">
               Transfer History
          </p>
          <div
               v-if="transaction_data && !transaction_data_loading"
               class="w-full flex flex-col justify-between items-center rounded-md h-full overflow-auto"
          >
               <table
                    class="font-roboto border-collapse border-spacing-4 table-auto w-full whitespace-nowrap"
               >
                    <thead>
                         <tr
                              class="bg-moozi-1 text-white text-xs xs:text-sm font-bold"
                         >
                              <th class="text-center py-3">Date</th>
                              <th class="text-center py-3">Transaction</th>
                              <th class="text-center py-3">Amount</th>
                              <th class="text-center py-3">Status</th>
                         </tr>
                    </thead>
                    <tbody
                         v-if="transaction_data?.length != 0"
                         class="font-roboto text-xs lg:text-sm"
                    >
                         <tr
                              v-for="(data, index) in transaction_data"
                              :key="index"
                              class="border-2 rounded-md text-xs lg:text-sm tracking-wide"
                              :class="
                                   index % 2 == 1
                                        ? 'border-gray-180'
                                        : 'border-none'
                              "
                         >
                              <td
                                   v-if="index % 2 == 0"
                                   colspan="4"
                                   class="pt-2"
                              ></td>
                              <td
                                   v-if="index % 2 == 1"
                                   class="text-center py-2"
                              >
                                   {{ date6(new Date(data.created_at)) }}
                              </td>
                              <td
                                   v-if="index % 2 == 1"
                                   class="text-center"
                              >
                                   {{
                                        data.transaction_type == 1
                                             ? "Purchase"
                                             : data.transaction_type == 2
                                               ? "Withdraw"
                                               : "Bonus"
                                   }}
                              </td>
                              <td
                                   v-if="index % 2 == 1"
                                   class="text-center"
                              >
                                   <div
                                        class="flex item-center justify-center font-bold text-right space-x-5"
                                   >
                                        <div
                                             class="flex items-center justify-center space-x-0.5"
                                        >
                                             <img
                                                  :class="
                                                       data.transaction_type ==
                                                       3
                                                            ? 'block h-4'
                                                            : 'hidden'
                                                  "
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/coin.png'
                                                  "
                                                  alt=""
                                             />
                                             <div>
                                                  {{
                                                       data.transaction_type ==
                                                       3
                                                            ? money(
                                                                   JSON.parse(
                                                                        data.conversion
                                                                   ).gold_coins
                                                              )
                                                            : "-$" +
                                                              money(
                                                                   data.net_amount
                                                              )
                                                  }}
                                             </div>
                                        </div>
                                        <div
                                             class="flex items-center justify-end space-x-0.5"
                                             :class="
                                                  data.transaction_type == 3
                                                       ? ''
                                                       : 'hidden'
                                             "
                                        >
                                             <img
                                                  :class="
                                                       data.transaction_type ==
                                                       3
                                                            ? 'block h-4'
                                                            : 'hidden'
                                                  "
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/coin-green.png'
                                                  "
                                                  alt=""
                                             />
                                             <div>
                                                  {{
                                                       data.transaction_type ==
                                                       3
                                                            ? money(
                                                                   JSON.parse(
                                                                        data.conversion
                                                                   )
                                                                        .silver_coins
                                                              )
                                                            : "$" +
                                                              money(
                                                                   data.net_amount
                                                              )
                                                  }}
                                             </div>
                                        </div>
                                   </div>
                              </td>
                              <td
                                   v-if="index % 2 == 1"
                                   class="text-center"
                              >
                                   <div
                                        class="py-2 flex items-center justify-center"
                                   >
                                        <div
                                             :class="{
                                                  'bg-secondary-6':
                                                       data.payment_status == 0,
                                                  'bg-primal-green':
                                                       data.payment_status == 1,
                                                  'bg-primal-red':
                                                       data.payment_status == 2
                                             }"
                                             class="rounded-full w-2 h-2 mr-2"
                                        ></div>
                                        <span>
                                             {{
                                                  data.payment_status == 0
                                                       ? "Pending"
                                                       : data.payment_status ==
                                                           1
                                                         ? "Success"
                                                         : "Failed"
                                             }}
                                        </span>
                                   </div>
                              </td>
                         </tr>
                    </tbody>
               </table>
               <div
                    v-if="transaction_data?.length == 0"
                    class="w-full h-full flex justify-center items-center text-moozi-1 font-medium tracking-wide"
               >
                    No transaction found.
               </div>
               <div
                    v-if="transaction_data.length != 0"
                    @click="goToTransaction"
                    class="w-full mt-5 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full bg-secondary-3 text-primary-1 text-sm font-extrabold cursor-pointer"
               >
                    See More Transaction
               </div>
          </div>
          <div
               v-else-if="transaction_data_loading"
               class="w-full px-3 py-2 bg-gray-90 rounded-md flex flex-row justify-center items-center h-36 mb-3 relative"
          >
               <loading-small :isLoading="transaction_data_loading" />
          </div>
     </div>
</template>
<script>
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";
export default {
     props: ["transaction_data", "transaction_data_loading", "goToTransaction"],
     setup() {
          const { date6, money } = useFormatter();

          return {
               date6,
               money,
               baseUrl
          };
     }
};
</script>
