import { useToast } from "vue-toastification";
import Toaster from "components/toast/toast.vue";

export const useToaster = () => {
     const toast = useToast();

     const successToast = (message, position, id) => {
          toast.success(
               {
                    component: Toaster,
                    props: {
                         title: "Success",
                         message: message,
                         id: id ?? ""
                    }
               },
               {
                    toastClassName: "bg-success",
                    position: position,
                    icon: "icon-success text-3xl"
               }
          );
     };

     const errorToast = (message, position) => {
          toast.error(
               {
                    component: Toaster,
                    props: {
                         title: "Error",
                         message: message
                    }
               },
               {
                    toastClassName: "bg-error-2",
                    position: position,
                    icon: "icon-error text-3xl"
               }
          );
     };

     const infoToast = (message, position) => {
          toast.info(
               {
                    component: Toaster,
                    props: {
                         title: "Info",
                         message: message
                    }
               },
               {
                    toastClassName: "bg-info",
                    position: position,
                    icon: "icon-info text-3xl"
               }
          );
     };

     const warningToast = (message, position) => {
          toast.warning(
               {
                    component: Toaster,
                    props: {
                         title: "Warning",
                         message: message
                    }
               },
               {
                    toastClassName: "bg-warning",
                    position: position,
                    icon: "icon-warning text-3xl"
               }
          );
     };

     return {
          successToast,
          errorToast,
          infoToast,
          warningToast
     };
};
