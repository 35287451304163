import { authStore } from "store/auth.js";
import { computed } from "vue";
import _ from "underscore";

export const useActionRestriction = () => {
     const useAuthStore = authStore();

     const restricted = computed(() => {
          return useAuthStore.actionRestricted;
     });

     const isActionRestricted = (action_type) => {
          const filter = _.find(restricted.value, function (params) {
               return params["action"] == action_type;
          });

          return filter != null;
     };

     return { isActionRestricted };
};
