import { getPolicy as getPolicyAPI } from "@/api/policy";
import { defineStore } from "pinia";

export const policyStore = defineStore("policy", {
     state: () => {
          return {
               policy: null
          };
     },
     actions: {
          getPolicy() {
               if (this.policy != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getPolicyAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.policy = response;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              // errorToast('Error to load data!', "top-right");
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          }
     }
});
