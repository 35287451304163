<template>
     <!-- @click="onSetActive('recent-winner')" -->
     <Slider
          v-if="type == 'slider'"
          :data="games"
          title="Recent winner"
          game_type="recent-winner"
          :onSetActive="onSetActive"
     >
          <template v-slot:icon>
               <span v-html="decodeIcon(icon)"></span>
          </template>
     </Slider>
     <Paginate
          v-else-if="type == 'paginate'"
          :data="games"
          title="Recent Winner"
          :onSeeMore="() => {}"
          :pagination="null"
          game_type="recent-winner"
     >
          <template v-slot:icon>
               <span v-html="decodeIcon(icon)"></span>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Slider from "components/winner/slider/index.vue";
import Paginate from "components/winner/paginate/index.vue";
import { useFormatter } from "composables/use-formatter.js";

export default {
     components: {
          Slider,
          Paginate
     },
     props: ["type", "onSetActive", "icon"],
     setup() {
          const useGamesStore = gamesStore();

          const { decodeIcon } = useFormatter();

          const games = reactive({
               data: null
          });

          const winnerData = computed(() => {
               if (useGamesStore.winner) {
                    games.data = useGamesStore.winner;
               }
               return useGamesStore.winner || null;
          });

          watch(winnerData, (newVal) => {
               if (newVal) {
                    games.data = newVal;
               }
          });

          return {
               games,
               decodeIcon
          };
     }
};
</script>
