import request from "@/utils/request";

export function getTierList(data) {
     return request({
          url: "/utils_tierlist",
          method: "get",
          data: data
     });
}

export function getUserTier(data) {
     return request({
          url: "/user/tier",
          method: "get",
          data: data
     });
}
