<template>
     <form
          @submit.prevent="passwordUpdate"
          class="flex-col flex justify-between h-full"
          style="min-height: 400px"
     >
          <div class="p-3 xs:p-0">
               <div class="space-y-3">
                    <transition
                         enter-from-class="opacity-0"
                         enter-active-class="transition ease-in duration-300"
                         enter-to-class="opacity-100 scale-100"
                    >
                         <div
                              v-if="checkMetaSocialSignup()"
                              class="rounded-xl !mt-0 bg-background-3 text-white flex flex-row items-center gap-3 ease-in-out transition-all duration-300 p-3 mb-5 w-full"
                         >
                              <svg
                                   class="w-5 h-5"
                                   viewBox="0 0 20 20"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        d="M10 7V11M10 14V14.01M1 10C1 11.1819 1.23279 12.3522 1.68508 13.4442C2.13738 14.5361 2.80031 15.5282 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C11.1819 19 12.3522 18.7672 13.4442 18.3149C14.5361 17.8626 15.5282 17.1997 16.364 16.364C17.1997 15.5282 17.8626 14.5361 18.3149 13.4442C18.7672 12.3522 19 11.1819 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C2.80031 4.47177 2.13738 5.46392 1.68508 6.55585C1.23279 7.64778 1 8.8181 1 10Z"
                                        stroke="#FFF"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                   />
                              </svg>
                              <p
                                   class="w-full font-roboto text-sm font-medium tracking-wider"
                              >
                                   It appears that you have created your Moozi
                                   account with your social account. You can
                                   setup a password here.
                              </p>
                         </div>
                    </transition>
                    <div
                         v-if="!checkMetaSocialSignup()"
                         class="relative"
                    >
                         <label
                              for="cur_pass"
                              class="block mb-2 font-roboto text-sm text-moozi-1 font-bold"
                         >
                              Current Password
                         </label>
                         <input
                              v-model="form.current_password"
                              :type="showCurrentPassword ? 'text' : 'password'"
                              name="cur_pass"
                              id="cur_pass"
                              class="bg-white border border-gray-100 text-gray-900 text-xs rounded-lg focus:ring-background-2 focus:border-background-2 block w-full p-2.5 tracking-wider"
                              :class="
                                   form.errors?.errors?.current_password != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red focus:bg-red-20 bg-red-20'
                                        : 'border-gray-100 focus:ring-background-2 focus:border-background-2 '
                              "
                              placeholder="Enter your current password"
                              autocomplete="current password"
                              @change="clearFormError('current_password')"
                         />
                         <has-error-form
                              v-model:form="form"
                              :field="'current_password'"
                         ></has-error-form>
                         <div
                              class="absolute cursor-pointer mt-0 top-10 right-3"
                         >
                              <span
                                   class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                                   @click="toggleShowPassword('current')"
                              >
                                   <svg
                                        v-if="showCurrentPassword"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                   >
                                        <path
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                   </svg>
                                   <svg
                                        v-else
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="{1.5}"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                   >
                                        <path
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                             d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                        />
                                   </svg>
                              </span>
                         </div>
                    </div>
                    <div class="relative">
                         <label
                              for="cur_pass"
                              class="block mb-2 font-roboto text-sm text-moozi-1 font-bold"
                         >
                              New Password
                         </label>
                         <input
                              v-model="form.new_password"
                              :type="showNewPassword ? 'text' : 'password'"
                              name="new_pass"
                              id="new_pass"
                              class="bg-white border border-gray-100 text-gray-900 text-xs rounded-lg focus:ring-background-2 focus:border-background-2 block w-full p-2.5 tracking-wider"
                              :class="
                                   form.errors?.errors?.new_password != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red focus:bg-red-20 bg-red-20'
                                        : 'border-gray-100 focus:ring-background-2 focus:border-background-2 '
                              "
                              placeholder="Enter your new password"
                              autocomplete="new password"
                              @change="clearFormError('new_password')"
                         />
                         <has-error-form
                              v-model:form="form"
                              :field="'new_password'"
                         ></has-error-form>
                         <div
                              class="absolute cursor-pointer mt-0 top-10 right-3"
                         >
                              <span
                                   class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                                   @click="toggleShowPassword('new')"
                              >
                                   <svg
                                        v-if="showNewPassword"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                   >
                                        <path
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                   </svg>
                                   <svg
                                        v-else
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="{1.5}"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                   >
                                        <path
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                             d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                        />
                                   </svg>
                              </span>
                         </div>
                    </div>
                    <div class="relative">
                         <label
                              for="cur_pass"
                              class="block mb-2 font-roboto text-sm text-moozi-1 font-bold"
                         >
                              Confirm Password
                         </label>
                         <input
                              v-model="form.new_password_confirmation"
                              :type="showConfirmPassword ? 'text' : 'password'"
                              name="con_pass"
                              id="con_pass"
                              class="bg-white border border-gray-100 text-gray-900 text-xs rounded-lg focus:ring-background-2 focus:border-background-2 block w-full p-2.5 tracking-wider"
                              :class="
                                   form.errors?.errors
                                        ?.new_password_confirmation != null
                                        ? 'border-primal-red focus:ring-primal-red focus:border-primal-red focus:bg-red-20 bg-red-20'
                                        : 'border-gray-100 focus:ring-background-2 focus:border-background-2 '
                              "
                              placeholder="Enter your confirm password"
                              autocomplete="confirm password"
                              @change="
                                   clearFormError('new_password_confirmation')
                              "
                         />
                         <has-error-form
                              v-model:form="form"
                              :field="'new_password_confirmation'"
                         ></has-error-form>
                         <div
                              class="absolute cursor-pointer mt-0 top-10 right-3"
                         >
                              <span
                                   class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                                   @click="toggleShowPassword('confirm')"
                              >
                                   <svg
                                        v-if="showConfirmPassword"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                   >
                                        <path
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                   </svg>
                                   <svg
                                        v-else
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="{1.5}"
                                        stroke="currentColor"
                                        class="w-5 h-5"
                                   >
                                        <path
                                             strokeLinecap="round"
                                             strokeLinejoin="round"
                                             d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                        />
                                   </svg>
                              </span>
                         </div>
                    </div>
               </div>
          </div>
          <div class="pt-6 text-right">
               <button
                    type="submit"
                    class="w-full px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full bg-secondary-3 text-primary-1 text-sm font-extrabold"
               >
                    Save
               </button>
          </div>
     </form>
</template>
<script>
import { ref, reactive, watch, computed } from "vue";
import _ from "underscore";
import HasErrorForm from "components/haserror/index.vue";
import Form from "vform";
import { authStore } from "@/store/auth";
import { useToaster } from "composables/use-toaster.js";

export default {
     props: {
          isUser: {
               type: Object,
               required: true
          },
          loading: {
               type: Boolean,
               required: true
          }
     },
     components: {
          HasErrorForm
     },
     setup(props, { emit }) {
          const useAuthStore = authStore();
          const { successToast } = useToaster();

          const showCurrentPassword = ref(false);
          const showNewPassword = ref(false);
          const showConfirmPassword = ref(false);

          const form = reactive(
               new Form({
                    current_password: "",
                    new_password: "",
                    new_password_confirmation: ""
               })
          );

          const error_msg = computed(() => {
               return useAuthStore.error_msg;
          });

          const toggleShowPassword = (type) => {
               if (type == "current") {
                    showCurrentPassword.value = !showCurrentPassword.value;
               } else if (type == "new") {
                    showNewPassword.value = !showNewPassword.value;
               } else if (type == "confirm") {
                    showConfirmPassword.value = !showConfirmPassword.value;
               }
          };

          const passwordUpdate = async () => {
               emit("update:loading", true);

               if (checkMetaSocialSignup()) {
                    delete form["current_password"];
               }

               await useAuthStore
                    .changePassword(form)
                    .then((res) => {
                         form.reset();
                         emit("close-modal");
                         emit("update:loading", false);
                         successToast(res.message, "top-right");
                         if (checkMetaSocialSignup()) {
                              useAuthStore.forceGetUser();
                         }
                    })
                    .catch(() => {
                         emit("update:loading", false);
                    });
          };

          const checkMetaSocialSignup = () => {
               const filtered = _.filter(props.isUser?.meta, function (params) {
                    return params["meta_key"] == "social_signup";
               });
               if (filtered.length) {
                    return true;
               } else {
                    return false;
               }
          };

          const clearFormError = (key) => {
               useAuthStore.clearCurrentFormError(key);
          };

          watch(error_msg, (newVal) => {
               if (newVal) {
                    form.errors.errors = newVal;
               }
          });

          return {
               showCurrentPassword,
               showNewPassword,
               showConfirmPassword,
               toggleShowPassword,
               checkMetaSocialSignup,
               clearFormError,
               passwordUpdate,
               form
          };
     }
};
</script>
