<template>
     <InertiaHead title="Register"></InertiaHead>

     <div class="w-full h-full relative flex justify-center mt-0">
          <div
               class="z-0 w-full h-100-vh welcome-bg-container bg-moozi-1 fixed top-0 left-0"
          ></div>
          <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               <div
                    class="w-full h-full flex flex-col lg:flex-row justify-start md:justify-center items-center px-4 xs:px-4 sm:px-8 md:px-8 lg:px-12 xl:px-24 pt-14 pb-8 xs:pb-12 sm:pb-18 md:pb-24 lg:pb-28 xl:pb-32 gap-4 relative"
               >
                    <div
                         class="hidden lg:flex h-full flex-1 flex-col justify-end items-center relative z-10"
                    >
                         <h1
                              class="text-white font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl italic relative z-10"
                         >
                              JOIN US NOW!
                         </h1>
                         <h2
                              class="text-white font-helvetica tracking-wide text-center text-3xl xs:text-3xl sm:text-4xl md:text-4xl xl:text-5xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl relative z-10"
                         >
                              TO OBTAIN
                              <span class="text-secondary-3">50,000</span> GOLD
                              COINS
                         </h2>
                         <div
                              class="w-full flex justify-center items-center absolute top-0 h-full xl:h-0"
                         >
                              <img
                                   id="dash-login"
                                   class="absolute w-auto"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/auth/hero-animated-dash.png'
                                   "
                                   alt=""
                              />
                              <img
                                   id="coin-login"
                                   class="absolute w-auto z-0"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/auth/hero-animated-coin.png'
                                   "
                                   alt=""
                              />
                              <img
                                   id="cow-login"
                                   class="absolute w-auto z-0"
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/auth/hero-animated-cow.png'
                                   "
                                   alt=""
                              />
                         </div>
                    </div>
                    <div
                         class="flex-initial justify-center items-center max-w-md w-full"
                    >
                         <div class="relative tracking-tight m-auto">
                              <div
                                   class="relative bg-moozi-2 rounded-lg shadow-xl"
                              >
                                   <div class="text-right pt-5 pb-2 mb-2">
                                        <div class="px-4 xs:px-6">
                                             <h3
                                                  class="text-xl xs:text-3xl font-semibold uppercase text-shade-1"
                                             >
                                                  Sign Up
                                             </h3>
                                        </div>
                                   </div>
                                   <div class="px-4 xs:px-6 font-inter">
                                        <form
                                             @submit.prevent="submitRegister"
                                             class="space-y-3"
                                        >
                                             <div>
                                                  <label
                                                       for="email"
                                                       class="block mb-2 text-base font-medium text-moozi-6"
                                                       >Email Address
                                                  </label>
                                                  <div class="relative">
                                                       <input
                                                            v-model="form.email"
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5"
                                                            placeholder="Enter your email"
                                                            autocomplete="username"
                                                            @change="
                                                                 clearFormError(
                                                                      'email'
                                                                 )
                                                            "
                                                            :class="
                                                                 form.errors
                                                                      ?.errors
                                                                      ?.email !=
                                                                 null
                                                                      ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                                      : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                                                            "
                                                       />
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'email'"
                                                  ></has-error-form>
                                             </div>
                                             <div>
                                                  <label
                                                       for="password"
                                                       class="block mb-2 text-base font-medium text-moozi-6"
                                                       >Password</label
                                                  >
                                                  <div class="relative">
                                                       <input
                                                            v-model="
                                                                 form.password
                                                            "
                                                            :type="
                                                                 showPassword
                                                                      ? 'text'
                                                                      : 'password'
                                                            "
                                                            name="password"
                                                            id="password"
                                                            placeholder="Enter your password"
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 pr-10"
                                                            autocomplete="current-password"
                                                            @change="
                                                                 clearFormError(
                                                                      'password'
                                                                 )
                                                            "
                                                            :class="
                                                                 form.errors
                                                                      ?.errors
                                                                      ?.password !=
                                                                      null ||
                                                                 passwordMismatch
                                                                      ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                                      : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                                                            "
                                                       />
                                                       <div
                                                            class="absolute cursor-pointer"
                                                            style="
                                                                 top: 12px;
                                                                 right: 8px;
                                                            "
                                                       >
                                                            <span
                                                                 class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                                                                 @click="
                                                                      toggleShowPassword()
                                                                 "
                                                            >
                                                                 <span
                                                                      v-if="
                                                                           showPassword
                                                                      "
                                                                      class="icon-eye text-xl text-shade-8"
                                                                      ><span
                                                                           class="path1"
                                                                      ></span
                                                                      ><span
                                                                           class="path2"
                                                                      ></span
                                                                      ><span
                                                                           class="path3"
                                                                      ></span
                                                                 ></span>
                                                                 <span
                                                                      v-else
                                                                      class="icon-eye-close text-2xl text-shade-8"
                                                                 ></span>
                                                            </span>
                                                       </div>
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'password'"
                                                  ></has-error-form>
                                             </div>
                                             <div>
                                                  <label
                                                       for="password"
                                                       class="block mb-2 text-base font-medium text-moozi-6"
                                                       >Confirm Password</label
                                                  >
                                                  <div class="relative">
                                                       <input
                                                            v-model="
                                                                 form.password_confirmation
                                                            "
                                                            :type="
                                                                 showPasswordConfirm
                                                                      ? 'text'
                                                                      : 'password'
                                                            "
                                                            name="password_confirmation"
                                                            id="password_confirmation"
                                                            placeholder="Repeat your password"
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5 pr-10"
                                                            autocomplete="current-password"
                                                            @change="
                                                                 clearFormError(
                                                                      'password_confirmation'
                                                                 )
                                                            "
                                                            :class="
                                                                 form.errors
                                                                      ?.errors
                                                                      ?.password_confirmation !=
                                                                      null ||
                                                                 passwordMismatch
                                                                      ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                                      : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                                                            "
                                                       />
                                                       <div
                                                            class="absolute cursor-pointer"
                                                            style="
                                                                 top: 12px;
                                                                 right: 8px;
                                                            "
                                                       >
                                                            <span
                                                                 class="items-center leading-normal bg-grey-lighter rounded rounded-r-none whitespace-no-wrap"
                                                                 @click="
                                                                      toggleShowPasswordConfirm()
                                                                 "
                                                            >
                                                                 <span
                                                                      v-if="
                                                                           showPasswordConfirm
                                                                      "
                                                                      class="icon-eye text-xl text-shade-8"
                                                                      ><span
                                                                           class="path1"
                                                                      ></span
                                                                      ><span
                                                                           class="path2"
                                                                      ></span
                                                                      ><span
                                                                           class="path3"
                                                                      ></span
                                                                 ></span>
                                                                 <span
                                                                      v-else
                                                                      class="icon-eye-close text-2xl text-shade-8"
                                                                 ></span>
                                                            </span>
                                                       </div>
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'password'"
                                                  ></has-error-form>
                                             </div>
                                             <div>
                                                  <label
                                                       for="referral_code"
                                                       class="block mb-2 text-base font-medium text-background-4"
                                                       >Referral Code
                                                  </label>
                                                  <div class="relative">
                                                       <input
                                                            v-model="
                                                                 form.referral_code
                                                            "
                                                            type="text"
                                                            name="referral_code"
                                                            id="referral_code"
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5"
                                                            placeholder="Referral Code"
                                                            autocomplete="on"
                                                            @change="
                                                                 clearFormError(
                                                                      'referral_code'
                                                                 )
                                                            "
                                                            :readonly="
                                                                 isReferralReadOnly
                                                            "
                                                            :class="
                                                                 form.errors
                                                                      ?.errors
                                                                      ?.referral_code !=
                                                                 null
                                                                      ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                                      : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                                                            "
                                                       />
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'referral_code'"
                                                  ></has-error-form>
                                             </div>

                                             <!-- <div>
                                                  <label
                                                       for="referral_code"
                                                       class="block mb-2 text-base font-medium text-background-4"
                                                  >Referral Code
                                                  </label>
                                                  <div class="relative">
                                                       <input
                                                            v-model="form.referral_code"
                                                            type="text"
                                                            name="referral_code"
                                                            id="referral_code"
                                                            class="bg-white border border-background-3 text-shade-2 text-base rounded-md focus:ring-background-2 focus:border-background-2 block w-full p-2.5"
                                                            placeholder="Referral Code"
                                                            autocomplete="on"
                                                            @change="clearFormError('referral_code')"
                                                            :readonly="isReferralReadOnly"
                                                            :class="
                                                            form.errors?.errors?.referral_code != null
                                                                 ? 'border-primal-red focus:ring-primal-red focus:border-primal-red'
                                                                 : 'border-background-3 focus:ring-background-2 focus:border-background-2'
                                                            "
                                                       />
                                                  </div>
                                                  <has-error-form
                                                       v-model:form="form"
                                                       :field="'referral_code'"
                                                  ></has-error-form>
                                             </div> -->

                                             <div
                                                  v-if="passwordMismatch"
                                                  class="text-primal-red mt-2"
                                             >
                                                  Passwords do not match.
                                             </div>
                                             <div class="flex justify-between">
                                                  <div class="flex items-start">
                                                       <div
                                                            class="flex items-center h-5 mt-1"
                                                       >
                                                            <input
                                                                 id="remember"
                                                                 type="checkbox"
                                                                 value=""
                                                                 class="w-4 h-4 border border-shade-7 rounded bg-shade-1 focus:ring-1 focus:ring-shade-7 text-background-3"
                                                                 required
                                                            />
                                                       </div>
                                                       <label
                                                            for="remember"
                                                            class="ms-2 text-base font-medium text-moozi-6"
                                                            >I agree to the
                                                            <router-link
                                                                 :to="{
                                                                      path: '/terms-of-use'
                                                                 }"
                                                                 target="_blank"
                                                            >
                                                                 <span
                                                                      class="text-base font-medium text-moozi-4 cursor-pointer"
                                                                      >Terms of
                                                                      Service</span
                                                                 > </router-link
                                                            >,
                                                            <router-link
                                                                 :to="{
                                                                      path: '/moozi-rules'
                                                                 }"
                                                                 target="_blank"
                                                            >
                                                                 <span
                                                                      class="text-base font-medium text-moozi-4 cursor-pointer"
                                                                      >Moozi
                                                                      Rules</span
                                                                 ></router-link
                                                            >, and
                                                            <router-link
                                                                 :to="{
                                                                      path: '/privacy'
                                                                 }"
                                                                 target="_blank"
                                                            >
                                                                 <span
                                                                      class="text-base font-medium text-moozi-4 cursor-pointer"
                                                                      >Privacy
                                                                      Policy</span
                                                                 ></router-link
                                                            ></label
                                                       >
                                                  </div>
                                             </div>
                                             <vue-recaptcha
                                                  v-if="initRecaptcha"
                                                  :sitekey="
                                                       VITE_RECAPTCHA_SITE_KEY
                                                  "
                                                  @verify="verifyMethod"
                                                  @expired="expiredMethod"
                                                  @render="renderMethod"
                                                  @error="errorMethod"
                                                  class="mb-5 w-full flex items-center justify-center"
                                             ></vue-recaptcha>
                                             <button
                                                  :disabled="
                                                       (tokenResponse == null &&
                                                            form.errors?.errors
                                                                 ?.password !=
                                                                 null) ||
                                                       form.errors?.errors
                                                            ?.password_confirmation !=
                                                            null ||
                                                       passwordMismatch
                                                  "
                                                  :class="
                                                       tokenResponse
                                                            ? ''
                                                            : 'opacity-70 cursor-not-allowed'
                                                  "
                                                  type="submit"
                                                  class="w-full text-primary-1 bg-secondary-3 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-roboto font-bold text-sm sm:text-base md:text-lg lg:text-base xl:text-lg"
                                             >
                                                  Create An Account
                                             </button>
                                             <div
                                                  class="flex items-center px-4 lg:px-6 text-center text-background-3 text-base font-bold w-full"
                                             >
                                                  <span
                                                       class="flex-1 h-px bg-background-3"
                                                  ></span>
                                                  <span
                                                       class="flex-initial px-4"
                                                       >Or</span
                                                  >
                                                  <span
                                                       class="flex-1 h-px bg-background-3"
                                                  ></span>
                                             </div>
                                             <button
                                                  @click.prevent="
                                                       googleRegister()
                                                  "
                                                  class="flex items-center w-full text-primary-1 bg-shade-1 px-6 sm:px-10 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold border border-shade-6"
                                             >
                                                  <img
                                                       class="w-auto flex-initial"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/auth/google.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <span
                                                       class="flex-1 text-sm sm:text-base md:text-lg lg:text-base"
                                                       >Sign Up With
                                                       Google</span
                                                  >
                                             </button>
                                             <button
                                                  @click.prevent="
                                                       twitterRegister()
                                                  "
                                                  class="flex items-center w-full text-primary-1 bg-shade-1 px-6 sm:px-10 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold border border-shade-6"
                                             >
                                                  <img
                                                       class="w-auto flex-initial"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/auth/x.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <span
                                                       class="flex-1 text-sm sm:text-base md:text-lg lg:text-base"
                                                       >Sign Up With X</span
                                                  >
                                             </button>

                                             <div
                                                  class="text-base font-medium text-background-3 text-center pt-2 pb-8"
                                             >
                                                  Already have an account?
                                                  <span
                                                       @click.prevent="
                                                            openLogin()
                                                       "
                                                       class="text-base text-secondary-1 underline cursor-pointer"
                                                       >Log In</span
                                                  >
                                             </div>
                                        </form>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <loading-overlay :isLoading="loading" />
               </div>
          </div>
     </div>
</template>

<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { onMounted, ref, reactive, computed, watch } from "vue";
import { authStore } from "store/auth";
import Form from "vform";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useRouter } from "vue-router";
import { utilitiesStore } from "store/utilities.js";
import HasErrorForm from "components/haserror/index.vue";
import { gsap } from "gsap";
import { useToaster } from "composables/use-toaster.js";
import device from "middleware/device.js";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";
const VITE_RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;
import { VueRecaptcha } from "vue-recaptcha";
import Cookies from "js-cookie";

export default {
     components: {
          LoadingOverlay,
          HasErrorForm,
          InertiaHead,
          VueRecaptcha
     },
     setup() {
          const useAuthStore = authStore();
          const router = useRouter();
          const { scrollTop } = useScroll();

          const loading = ref(false);

          const tokenResponse = ref(null);
          const initRecaptcha = ref(false);

          const showPassword = ref(false);
          const showPasswordConfirm = ref(false);
          const useUtilitiesStore = utilitiesStore();

          const { infoToast } = useToaster();
          const { deviceType } = device();

          const isReferralReadOnly = ref(false);

          const form = reactive(
               new Form({
                    email: "",
                    password: "",
                    password_confirmation: "",
                    "g-recaptcha-response": null,
                    referral_code: ""
               })
          );

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const error_msg = computed(() => {
               return useAuthStore.error_msg;
          });

          const openLogin = () => {
               useUtilitiesStore.setMenuGuest(false);
               form.reset();
               router.push({ name: "login" });
          };

          const submitRegister = () => {
               form["g-recaptcha-response"] = tokenResponse.value;
               loading.value = true;
               useAuthStore
                    .register(form)
                    .then(() => {
                         form.reset();

                         const triggerTTD =
                              document.getElementById("register-ttd");
                         if (triggerTTD) {
                              triggerTTD.setAttribute(
                                   "src",
                                   "https://insight.adsrvr.org/track/pxl/?adv=nqpc8db&ct=0:vk4ykmz&fmt=3"
                              );
                         }

                         initRecaptcha.value = false;
                         setTimeout(() => {
                              loading.value = false;
                              initRecaptcha.value = true;
                         }, 1000);

                         setTimeout(() => {
                              router.push({ name: "login" });
                         }, 2000);
                    })
                    .catch(() => {
                         initRecaptcha.value = false;
                         setTimeout(() => {
                              loading.value = false;
                              initRecaptcha.value = true;
                         }, 1000);
                    });
          };

          const clearFormError = (key) => {
               if (typeof form.errors.errors[key] !== "undefined") {
                    delete form.errors.errors[key];
               }
          };

          const toggleShowPassword = () => {
               showPassword.value = !showPassword.value;
          };

          const toggleShowPasswordConfirm = () => {
               showPasswordConfirm.value = !showPasswordConfirm.value;
          };

          const passwordMismatch = ref(false);

          watch(
               () => [form.password, form.password_confirmation],
               ([newPassword, newPasswordConfirmation]) => {
                    passwordMismatch.value =
                         newPassword &&
                         newPasswordConfirmation &&
                         newPassword !== newPasswordConfirmation;
               }
          );

          const googleRegister = () => {
               window.location.href =
                    "/auth/google?type" +
                    (deviceType() == "mobile" ? "app" : "web");
          };

          const facebookRegister = () => {
               infoToast("Meta Auth is under maintainance", "top-right");
               // window.location.href = "/auth/facebook?type"+(deviceType() == 'mobile'? 'app' : 'web');;
          };

          const twitterRegister = () => {
               window.location.href =
                    "/auth/twitter?type" +
                    (deviceType() == "mobile" ? "app" : "web");
          };

          // Autofill Referral Code from URL or Cookie
          const autofillReferralCode = () => {
               const urlParams = new URLSearchParams(window.location.search);
               const referralCode = urlParams.get("referral_code");

               if (referralCode) {
                    // Save referral code to cookie for 30 days
                    Cookies.set("referral_code", referralCode, { expires: 30 });
                    form.referral_code = referralCode;
                    isReferralReadOnly.value = true; // Make field read-only
               } else {
                    // Check if referral code exists in the cookie
                    const storedReferralCode = Cookies.get("referral_code");
                    if (storedReferralCode) {
                         form.referral_code = storedReferralCode;
                         isReferralReadOnly.value = true; // Make field read-only
                    }
               }
          };

          const cowAnimation = () => {
               gsap.timeline()
                    .from("#cow-register", {
                         opacity: 0,
                         xPercent: 50,
                         yPercent: -30,
                         scale: 0.5,
                         duration: 0.3,
                         ease: "sine.in"
                    })
                    .to("#cow-register", {
                         x: -10,
                         y: 10,
                         scale: 1,
                         opacity: 0.93,
                         duration: 0.2,
                         ease: "power1.out"
                    })
                    .to("#cow-register", {
                         x: 5,
                         y: -5,
                         scale: 0.96,
                         duration: 0.2,
                         ease: "power1.out"
                    })
                    .to("#cow-register", {
                         x: 0,
                         y: 0,
                         scale: 1,
                         opacity: 1,
                         duration: 0.3,
                         ease: "power1.out"
                    });
          };

          const dashAnimation = () => {
               gsap.timeline()
                    .from("#dash-register", {
                         opacity: 0,
                         duration: 0.3,
                         ease: "sine.in"
                    })
                    .to("#dash-register", {
                         opacity: 1,
                         duration: 0.2,
                         ease: "power1.out"
                    });
          };

          const coinAnimation = () => {
               gsap.timeline()
                    .from("#coin-register", {
                         opacity: 0,
                         duration: 0.3,
                         ease: "sine.in"
                    })
                    .to("#coin-register", {
                         opacity: 1,
                         duration: 0.2,
                         ease: "power1.out"
                    });
          };

          const verifyMethod = async (response) => {
               tokenResponse.value = response;
          };

          const expiredMethod = async () => {
               tokenResponse.value = null;
               console.error("expired");
          };

          const renderMethod = async () => {
               // console.log(id);
          };

          const errorMethod = async () => {
               tokenResponse.value = null;
               console.error("error");
          };

          onMounted(async () => {
               initRecaptcha.value = true;
               scrollTop();
               autofillReferralCode();
               if (!preloading.value) {
                    cowAnimation();
                    dashAnimation();
                    coinAnimation();
               }
          });

          watch(preloading, (newVal) => {
               if (!newVal) {
                    cowAnimation();
                    dashAnimation();
                    coinAnimation();
               }
          });

          watch(error_msg, (newVal) => {
               if (newVal) {
                    form.errors.errors = newVal;
               }
          });

          return {
               form,
               loading,
               showPassword,
               showPasswordConfirm,
               passwordMismatch,
               openLogin,
               submitRegister,
               toggleShowPassword,
               toggleShowPasswordConfirm,
               googleRegister,
               facebookRegister,
               twitterRegister,
               clearFormError,
               baseUrl,
               tokenResponse,
               VITE_RECAPTCHA_SITE_KEY,
               verifyMethod,
               expiredMethod,
               renderMethod,
               errorMethod,
               initRecaptcha,
               isReferralReadOnly
          };
     }
};
</script>
