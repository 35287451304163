import request from "@/utils/request";

export function saveSubs(data) {
     return request({
          url: "/user/subscription",
          method: "get",
          params: data
     });
}

export function getSubs() {
     return request({
          url: "/user/subscription_status",
          method: "get"
     });
}
