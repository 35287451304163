import { getSubs as getSubsAPI } from "@/api/subscription";
import { defineStore } from "pinia";

export const subscriptionStore = defineStore("subscription", {
     state: () => {
          return {
               subs: null
          };
     },
     actions: {
          getSubscription() {
               if (this.subs != null) {
                    return;
               }
               return new Promise((resolve, reject) => {
                    getSubsAPI()
                         .then((response) => {
                              this.$patch((state) => {
                                   state.subs = response?.status;
                              });
                              resolve();
                         })
                         .catch((error) => {
                              this.$patch((state) => {
                                   if (error.response) {
                                        state.error_msg = error.response.data;
                                   }
                              });
                              // errorToast('Error to load data!', "top-right");
                              console.error(
                                   "Error to load testimonials data!",
                                   error
                              );
                              reject(error);
                         });
               });
          }
     }
});
