<template>
     <Paginate
          :data="games"
          title="All Games"
          :onSeeMore="onSeeMore"
          :pagination="pagination"
          :game_type="game_type"
          :onSetActive="onSetActive"
          :isLobby="isLobby"
     >
          <template v-slot:icon>
               <span v-html="decodeIcon(icon)"></span>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Paginate from "components/games/paginate/index.vue";
import { useFormatter } from "composables/use-formatter.js";

export default {
     components: {
          Paginate
     },
     props: ["onSetActive", "active", "isLobby", "game_type", "icon"],
     setup(props) {
          const useGamesStore = gamesStore();

          const { decodeIcon } = useFormatter();

          const games = reactive({
               data: null
          });

          const params = reactive({});

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const gamesData = computed(() => {
               if (useGamesStore.allGames[props.game_type]) {
                    games.data =
                         useGamesStore.allGames[props.game_type]["data"];
                    pagination["current"] =
                         useGamesStore.allGames[props.game_type][
                              "current_page"
                         ];
                    pagination["last"] =
                         useGamesStore.allGames[props.game_type]["last_page"];
                    const temp =
                         useGamesStore.allGames[props.game_type][
                              "next_page_url"
                         ];
                    pagination["next"] =
                         temp != null ? temp.split("=")[1] : pagination["last"];
               }
               return useGamesStore.allGames[props.game_type] || null;
          });

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               return useGamesStore.gameFilter;
          });

          const onSeeMore = async () => {
               const data = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: params.provider_id,
                    page: parseInt(pagination["next"]),
                    itemsPerPage: 48,
                    sortType: params.sortType
               };

               await useGamesStore.getGames(data, props.game_type, false, true);
          };

          watch(gamesData, (newVal) => {
               if (newVal) {
                    if (newVal?.[props.game_type]) {
                         games.data = newVal[props.game_type]["data"];
                         pagination["current"] =
                              newVal[props.game_type]["current_page"];
                         pagination["last"] =
                              newVal[props.game_type]["last_page"];
                         const temp = newVal[props.game_type]["next_page_url"];
                         pagination["next"] =
                              temp != null
                                   ? temp.split("=")[1]
                                   : pagination["last"];
                    }
               }
          });

          watch(gameFilter, () => {});

          return {
               games,
               onSeeMore,
               pagination,
               decodeIcon
          };
     }
};
</script>
