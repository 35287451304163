<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenDeleteAccConfirm"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center m-auto font-helveticaLight flex justify-center items-center"
          >
               <div
                    class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-lg max-h-full m-auto"
               >
                    <!-- Modal content -->
                    <div class="relative bg-shade-1 rounded-xl shadow-xl">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div
                              class="flex flex-col items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 font-bold text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div class="pb-4 w-full text-center">
                                   <h3
                                        class="text-base xs:text-lg font-bold text-gray-900"
                                   >
                                        Oops!
                                   </h3>
                              </div>
                              <div class="pb-2 pt-2 xs:pt-6 w-full font-normal">
                                   <p class="px-2 md:px-6 lg:px-10">
                                        You do not have an account because it
                                        has been deleted or deactivated. If you
                                        believe this was an error, please
                                        contact us through our online chat or
                                        send an email to support@moozi.com for
                                        help.
                                   </p>
                                   <div
                                        class="pt-6 xs:pt-10 text-center w-full font-helveticaLight"
                                   >
                                        <div class="flex w-full gap-4">
                                             <button
                                                  @click="goToHome"
                                                  class="w-full text-primary-1 bg-secondary-3 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                             >
                                                  Go To Home
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <loading-overlay :isLoading="loading" />
     <div
          v-if="isOpenDeleteAccConfirm"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.3)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { useRouter } from "vue-router";
import { baseUrl } from "config/services.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";

export default {
     props: {
          email: {
               type: String,
               required: true
          },
          loginFunction: {
               type: Function,
               required: true
          }
     },
     components: {
          LoadingOverlay
     },
     setup() {
          const isOpenDeleteAccConfirm = ref(false);
          const loading = ref(false);
          const useUtilitiesStore = utilitiesStore();
          const router = useRouter();

          const closeModal = () => {
               isOpenDeleteAccConfirm.value = false;
               useUtilitiesStore.enableScroll();
          };

          const goToHome = async () => {
               closeModal(); // Close the modal
               router.push({ path: "/lobby" }); // Redirect to /lobby
          };

          onMounted(async () => {
               eventBus.on("open:deleted_account", () => {
                    useUtilitiesStore.disableScroll();
                    isOpenDeleteAccConfirm.value = true;
               });
          });

          onUnmounted(() => {
               eventBus.off("open:deleted_account");
          });

          return {
               isOpenDeleteAccConfirm,
               loading,
               closeModal,
               goToHome,
               baseUrl
          };
     }
};
</script>
