import { cardStore } from "store/cards.js";
import { computed } from "vue";
import _ from "underscore";

export const useBank = () => {
     const useCardStore = cardStore();
     const bankStatus = computed(() => {
          return useCardStore.bankStatus;
     });

     const isActionAccessible = (slug) => {
          const filter = _.find(bankStatus.value, function (params) {
               return params["slug"] == slug && params["note"] == 1;
          });

          return filter != null;
     };

     return { isActionAccessible };
};
