<template>
     <InertiaHead title="Privacy Policy"></InertiaHead>
     <div class="home-wrapper mt-0">
          <div
               class="banner flex bg-no-repeat w-full font-helveticaLight flex-col justify-center items-center relative"
          >
               <div class="text-center">
                    <div
                         class="pt-18 md:pt-22 lg:pt-28 text-white font-helvetica tracking-wide text-center text-4xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl uppercase px-2"
                    >
                         Privacy Policy
                    </div>
               </div>
               <div class="w-full flex justify-center items-center pb-20">
                    <div class="py-14 sm:py-16 md:py-20 lg:py-28">
                         <div
                              v-if="!loading"
                              class="pb-2"
                              v-html="content"
                         ></div>
                         <loading-small
                              v-else
                              :isLoading="loading"
                         />
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { ref, computed, onMounted } from "vue";
import { policyStore } from "store/policy.js";
import _ from "underscore";
import LoadingSmall from "components/loading/loadingSmall.vue";
import { useScroll } from "composables/use-scroll.js";

export default {
     components: {
          InertiaHead,
          LoadingSmall
     },
     setup() {
          const slug = "privacy";

          const { scrollTop } = useScroll();

          const usePolicyStore = policyStore();

          const loading = ref(false);

          const content = computed(() => {
               if (usePolicyStore.policy) {
                    const filter = _.find(
                         usePolicyStore.policy,
                         function (params) {
                              return params["slug"] == slug;
                         }
                    );

                    return filter ? filter.content : "";
               }

               return "";
          });

          onMounted(async () => {
               loading.value = true;
               scrollTop();
               await usePolicyStore.getPolicy();
               loading.value = false;
          });

          return {
               content,
               loading
          };
     }
};
</script>
