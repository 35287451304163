import { defineStore } from "pinia";

export const ioSocketStore = defineStore("iosocket", {
     state: () => {
          return {
               socket: null
          };
     },
     actions: {
          setSocket(socketIO) {
               if (this.socket != null) {
                    return;
               }
               this.$patch((state) => {
                    state.socket = socketIO;
               });
          }
     }
});
