<template>
     <InertiaHead title="Promotions"></InertiaHead>
     <div
          class="moozi-min-h w-full h-full relative flex justify-center bg-moozi-1"
     >
          <div class="w-full 2xl:max-w-screen-2xl bg-transparent relative">
               <div
                    class="w-full h-full flex flex-col justify-center items-center pb-20 lg:pb-26 pt-3 sm:pt-5 md:pt-10 lg:pt-12 xl:pt-14"
               >
                    <h1
                         class="hidden lg:block text-white font-helvetica tracking-wide text-center text-6xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                    >
                         MOOZI COINS PROMOTIONS
                    </h1>
                    <h1
                         class="block lg:hidden text-white font-helvetica tracking-wide text-center text-5xl xs:text-5xl sm:text-5xl md:text-6xl xl:text-8xl leading-5xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl"
                    >
                         PROMOTIONS
                    </h1>
                    <p
                         class="text-white font-helveticaLight tracking-wide mt-5 md:mt-8 mb-4 md:mb-8 text-center lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl"
                    >
                         Moozi has promotions updated every week. Keep an eye on
                         Promotions, claim your coin bonus!
                    </p>
                    <div class="mt-5 md:mt-7 lg:mt-12 xl:mt-20 w-full">
                         <div
                              class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 grid-row-6 lg:grid-row-3 2xl:grid-row-2 gap-5 md:gap-7 lg:gap-5 xl:gap-10"
                         >
                              <div
                                   v-for="(item, key) in promotion"
                                   :key="key"
                                   class="rounded-xl bg-gradient-card relative p-4 md:p-6 h-52 xs:h-60 sm:h-80 md:h-96 lg:h-64 xl:h-96 2xl:h-80 flex flex-col justify-between items-start cursor-pointer"
                                   @click="() => goto(item)"
                              >
                                   <div>
                                        <h3
                                             class="text-white whitespace-nowrap font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase"
                                        >
                                             <span>{{ item.title }}</span>
                                             {{ item.title_highlight }}
                                        </h3>
                                        <p
                                             v-html="item.sub_content"
                                             class="text-moozi-14 font-roboto font-bold tracking-wide mt-2 text-start text-sm xs:text-lg sm:text-xl md:text-2xl lg:text-lg xl:text-2xl 2xl:text-xl"
                                        ></p>
                                   </div>
                                   <div
                                        @click="() => goto(item)"
                                        class="w-max rounded-full font-roboto font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
                                   >
                                        See More
                                   </div>
                                   <img
                                        :src="item.icon"
                                        alt="chicken"
                                        class="bottom-0 right-0 absolute w-6/12 xs:w-5/12 sm:w-5/12 md:w-4/12 xl:w-5/12"
                                   />
                              </div>
                              <div
                                   v-if="
                                        promotion != null &&
                                        promotion?.length == 4
                                   "
                                   class="rounded-xl bg-pending-card relative p-4 md:p-6 h-52 xs:h-60 sm:h-80 md:h-96 lg:h-64 xl:h-96 2xl:h-80 flex flex-col justify-end"
                              >
                                   <div>
                                        <h3
                                             class="text-background-1 font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase"
                                        >
                                             More promotions are coming soon...
                                        </h3>
                                   </div>
                              </div>
                              <div
                                   v-if="
                                        promotion != null &&
                                        promotion?.length <= 4
                                   "
                                   class="rounded-xl bg-pending-card relative p-4 md:p-6 h-52 xs:h-60 sm:h-80 md:h-96 lg:h-64 xl:h-96 2xl:h-80 flex flex-col justify-end"
                              >
                                   <div>
                                        <h3
                                             class="text-background-1 font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase"
                                        >
                                             More promotions are coming soon...
                                        </h3>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { computed, onMounted, ref, watch } from "vue";
import { promoStore } from "store/promotion";
import { useRouter } from "vue-router";
import { useScroll } from "composables/use-scroll.js";

export default {
     components: {
          InertiaHead
     },
     setup() {
          const router = useRouter();
          const { scrollTop } = useScroll();

          const promotion = ref(null);

          const usePromoStore = promoStore();

          const promoData = computed(() => {
               if (usePromoStore.promo) {
                    promotion.value = usePromoStore.promo;
               }
               return usePromoStore.promo || null;
          });

          const goto = (item) => {
               router.push({
                    path:
                         "/promotion/detail/" +
                         item.title.replaceAll(" ", "-") +
                         "-" +
                         item.title_highlight.replaceAll(" ", "-") +
                         "-" +
                         item.id
               });
          };

          watch(promoData, (newVal) => {
               if (newVal) {
                    promotion.value = newVal;
               }
          });

          onMounted(() => {
               scrollTop();
               usePromoStore.getPromotion({ published_at: "promotion" });
          });

          return {
               promotion,
               promoData,
               goto
          };
     }
};
</script>
