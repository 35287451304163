// import { ElMessage } from 'element-plus';
import { isLogged, getToken } from "utils/auth.js";
import { useToaster } from "composables/use-toaster.js";
import { baseUrl } from "config/services.js";

const { errorToast } = useToaster();

// Create axios instance
const service = window.axios.create({
     baseURL: baseUrl.api,
     timeout: 60000 // Request timeout
});

// Request intercepter
service.interceptors.request.use(
     (config) => {
          const token = isLogged();
          if (token) {
               config.headers["Authorization"] = "Bearer " + getToken(); // Set JWT token
          }
          return config;
     },
     (error) => {
          // Do something with request error
          Promise.reject(error);
     }
);

// response pre-processing
service.interceptors.response.use(
     (response) => {
          // if (response.headers.authorization) {
          //   setLogged(response.headers.authorization);
          //   response.data.token = response.headers.authorization;
          // }

          return response.data;
     },
     (error) => {
          // let message = error.message;
          if (
               !error.response?.data?.status &&
               !error.response?.data?.error_code == "game_inactive"
          ) {
               window.location.href = "/error";
          } else if (
               error.response?.status == 503 &&
               error.response?.data.message == "unauthorized" &&
               !error.response?.data.status
          ) {
               window.location.reload();
          } else if (
               error.response?.status == 400 &&
               error.response?.data.message == "unauthorized" &&
               !error.response?.data.status
          ) {
               window.location.reload();
          } else if (error.response?.data && error.response?.data.message) {
               // message = error.response.data.message;
          } else if (
               error.response?.statusText == "Unauthorized" &&
               error.response?.config.url != "/login"
          ) {
               // window.location.reload();
          } else if (error.response?.data.error) {
               if (window.location.pathname != "/login") {
                    errorToast(
                         error.response.data?.message
                              ? error.response?.data.message
                              : error.response?.data.error,
                         "bottom-center"
                    );
               }
          }
          // ElMessage({
          //   message: message,
          //   type: 'error',
          //   duration: 5 * 1000,
          // });
          return Promise.reject(error);
     }
);

export default service;
