export const useImage = () => {
     const onImageError = (e) => {
          const broken = document.querySelectorAll(
               'img.broken-image[alt="' + e.target.alt + '"]'
          );
          const el = document.querySelectorAll(
               'img.thumbnail-image[alt="' + e.target.alt + '"]'
          );

          for (let index = 0; index < broken.length; index++) {
               broken[index].classList.remove("invisible");
          }

          for (let index = 0; index < el.length; index++) {
               el[index].style.visibility = "hidden";
          }
     };

     const onImageProviderError = (e) => {
          const broken = document.querySelectorAll(
               'li.provider-carousel-list[alt="' + e.target.alt + '"]'
          );

          for (let index = 0; index < broken.length; index++) {
               broken[index].remove();
          }
     };

     return { onImageError, onImageProviderError };
};
