<template>
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isOpenReminder"
               id="authentication-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-auto overflow-x-hidden fixed z-80 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center"
          >
               <div
                    class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto text-center"
               >
                    <!-- Modal content -->
                    <div class="relative bg-shade-1 rounded-xl shadow-xl">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                         >
                              <img
                                   :src="
                                        baseUrl.img +
                                        '/assets/img/modal/exclude.svg'
                                   "
                                   alt=""
                              />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div
                              class="flex flex-col divide-y divide-shade-12 items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight"
                         >
                              <div class="pb-4 w-full text-center">
                                   <h3
                                        class="text-base xs:text-lg font-bold text-gray-900"
                                   >
                                        Purchase limit Confirmation
                                   </h3>
                              </div>
                              <div class="pb-2 pt-6 w-full font-normal">
                                   <p class="md:px-6 lg:px-10">
                                        Once confirmed, you will be limited to
                                        purchase ${{ amountDailyVal }} within
                                        {{ durationDailyVal }} day(s).
                                   </p>
                                   <div
                                        class="pt-2 xs:pt-10 text-center w-full font-helveticaLight"
                                   >
                                        <div class="px-2 flex w-full gap-4">
                                             <button
                                                  @click="closeModal()"
                                                  class="w-full text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                             >
                                                  Cancel
                                             </button>
                                             <button
                                                  @click="toggle()"
                                                  class="w-full bg-primal-red text-white px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold"
                                             >
                                                  Confirm
                                             </button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isOpenReminder"
          class="fixed inset-0 z-60"
          style="background-color: rgba(0, 0, 0, 0.3)"
     ></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
     props: [
          "confirmPurchaseLimit",
          "cancelPurchaseLimit",
          "amountDailyVal",
          "durationDailyVal"
     ],
     setup(props) {
          const isOpenReminder = ref(false);
          const useUtilitiesStore = utilitiesStore();

          const toggleReminder = () => {
               isOpenReminder.value = !isOpenReminder.value;
          };

          const closeModal = () => {
               props.cancelPurchaseLimit();
               isOpenReminder.value = false;
               useUtilitiesStore.enableScroll();
          };

          const toggle = () => {
               props.confirmPurchaseLimit();
               closeModal();
          };

          onMounted(async () => {
               eventBus.on("open:purchase-limit-reminder", () => {
                    useUtilitiesStore.disableScroll();
                    toggleReminder();
               });
          });

          onUnmounted(() => {
               eventBus.off("open:purchase-limit-reminder");
          });

          return {
               isOpenReminder,
               closeModal,
               toggle,
               baseUrl
          };
     }
};
</script>
