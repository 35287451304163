<template>
     <div
          class="w-max h-10 lg:h-full bg-moozi-2 rounded-full flex cursor-pointer lg:relative border border-solid border-moozi-4 dropdown-trigger md:hover:border-white transition-colors duration-150"
     >
          <div
               @click="onDropdown()"
               class="flex flex-row gap-3 justify-center items-center w-10 lg:w-full lg:px-5 dropdown-trigger"
          >
               <svg
                    :class="isDropdown ? 'hidden lg:block' : ''"
                    class="w-4"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <rect
                         x="0.5"
                         y="0.5"
                         width="19"
                         height="19"
                         rx="4.5"
                         fill="#3B0F11"
                    />
                    <rect
                         x="0.5"
                         y="0.5"
                         width="19"
                         height="19"
                         rx="4.5"
                         stroke="white"
                    />
                    <path
                         d="M5 9.47826L9.81481 14L15 6"
                         stroke="white"
                         stroke-width="2"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                    />
               </svg>
               <svg
                    :class="isDropdown ? 'block lg:hidden' : 'hidden'"
                    class="w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
               >
                    <path
                         d="M9 8L15 16M15 8L9 16M12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21Z"
                         stroke="white"
                         stroke-width="2"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                    />
               </svg>
               <p
                    class="font-roboto font-medium text-white text-sm xl:text-base capitalize hidden lg:block dropdown-trigger-text text-nowrap overflow-hidden"
               >
                    Game Studios ({{ selectedDropdown.length }})
               </p>
          </div>
          <div
               v-if="isDropdown"
               id="dropdown-provider-list"
               class="absolute max-h-52 lg:max-h-48 overflow-y-hidden z-20 bg-moozi-2 flex flex-col justify-start rounded-xl w-full lg:w-44 xl:w-full top-14 lg:top-12 right-0 border border-solid border-moozi-4"
          >
               <div class="p-2 w-full">
                    <div
                         class="dropdown-search-wrapper border border-solid border-moozi-6 rounded-lg h-10 w-full flex items-center justify-start"
                    >
                         <svg
                              class="w-5 h-5 ml-2"
                              viewBox="0 0 22 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <path
                                   d="M17.6422 9.34086C17.6422 11.2611 17.0108 13.0236 15.985 14.444L21.2723 19.7313C21.7984 20.2574 21.7984 21.0992 21.2723 21.6253C20.7462 22.1514 19.9044 22.1514 19.3783 21.6253L14.091 16.338C12.6705 17.3902 10.8818 17.9952 8.98783 17.9952C4.20033 17.9952 0.333496 14.1284 0.333496 9.34086C0.333496 4.55335 4.17402 0.686523 8.96153 0.686523C13.749 0.686523 17.6159 4.55335 17.6159 9.34086H17.6422ZM8.96153 15.3647C12.276 15.3647 14.9591 12.6816 14.9591 9.36716C14.9591 6.05274 12.276 3.36963 8.96153 3.36963C5.6471 3.36963 2.96399 6.02643 2.96399 9.34086C2.96399 12.6553 5.6471 15.3384 8.96153 15.3384V15.3647Z"
                                   fill="#9D6465"
                              />
                         </svg>
                         <input
                              type="text"
                              class="dropdown-search search-game w-full rounded-full p-0 px-1 md:px-2 h-full !bg-moozi-2 font-roboto font-medium !text-white text-sm lg:text-base outline-none border-none !shadow-none focus:shadow-none"
                              @keyup="() => onSearch(search)"
                              placeholder="Search"
                              v-model="search"
                              style="width: inherit"
                         />
                         <svg
                              @click="resetList()"
                              class="h-6 mr-2 dropdown-search-clear"
                              :class="search == '' ? 'opacity-0 invisible' : ''"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                         >
                              <circle
                                   cx="10"
                                   cy="10"
                                   r="9.5"
                                   stroke="#9D6465"
                              />
                              <path
                                   d="M8.51151 7.14072C8.30199 7.016 8.05412 6.97213 7.81452 7.01735C7.57492 7.06258 7.3601 7.19379 7.21046 7.38631C7.06082 7.57882 6.98668 7.81938 7.00197 8.06273C7.01727 8.30608 7.12095 8.53546 7.29351 8.70772L8.58551 10.0007L7.29351 11.2937L7.21051 11.3877C7.0551 11.5887 6.98202 11.8413 7.00611 12.0943C7.0302 12.3472 7.14967 12.5814 7.34023 12.7495C7.5308 12.9175 7.77818 13.0067 8.03213 12.999C8.28608 12.9912 8.52755 12.8871 8.70751 12.7077L10.0005 11.4157L11.2935 12.7077L11.3875 12.7907C11.5885 12.9461 11.8411 13.0192 12.094 12.9951C12.347 12.971 12.5812 12.8516 12.7493 12.661C12.9173 12.4704 13.0065 12.2231 12.9987 11.9691C12.991 11.7152 12.8869 11.4737 12.7075 11.2937L11.4155 10.0007L12.7075 8.70772L12.7905 8.61372C12.9459 8.41273 13.019 8.16012 12.9949 7.9072C12.9708 7.65428 12.8514 7.42001 12.6608 7.25197C12.4702 7.08394 12.2228 6.99474 11.9689 7.0025C11.7149 7.01025 11.4735 7.11438 11.2935 7.29372L10.0005 8.58572L8.70751 7.29372L8.61351 7.21072L8.51151 7.14072Z"
                                   fill="#9D6465"
                              />
                         </svg>
                    </div>
               </div>
               <div class="dropdown-provider overflow-y-auto">
                    <div
                         v-for="(item, key) in list"
                         :key="key"
                         @click="onSelect(item.id)"
                         class="dropdown-list flex flex-row gap-3 items-center px-3 py-2"
                    >
                         <Check v-if="selectedDropdown.includes(item.id)" />
                         <Uncheck v-else />
                         <p
                              class="font-roboto font-normal text-white text-sm lg:text-base dropdown-text"
                         >
                              {{
                                   item.name == "All"
                                        ? (selectedDropdown.includes(0)
                                               ? "Unselect "
                                               : "Select ") + item.name
                                        : item.name
                              }}
                         </p>
                    </div>
               </div>
               <div
                    v-if="list.length == 0"
                    class="px-3 pt-2 pb-4"
               >
                    <p
                         class="font-roboto font-normal text-white text-sm lg:text-base dropdown-text text-center"
                    >
                         No Provider
                    </p>
               </div>
          </div>
     </div>
</template>
<script>
import Check from "components/checkbox/check.vue";
import Uncheck from "components/checkbox/uncheck.vue";
import { onMounted, computed, ref } from "vue";
import { gamesStore } from "store/games";
import _ from "underscore";

export default {
     props: [
          "isDropdown",
          "onDropdown",
          "selectedDropdown",
          "onSelect",
          "hideDropdown"
     ],
     components: {
          Check,
          Uncheck
     },
     setup(props) {
          const useGamesStore = gamesStore();

          const list = ref(null);
          const search = ref("");

          let timer,
               timeoutVal = 300;

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const onSearch = (query) => {
               if (query.toLowerCase() == "") {
                    search.value = "";
               }

               search.value = query.toLowerCase();

               window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
               timer = window.setTimeout(async () => {
                    list.value = _.filter(providerData.value, (params) => {
                         return params["name"]
                              .toLowerCase()
                              .includes(query.toLowerCase());
                    });
               }, timeoutVal);
          };

          const clearSearch = () => {
               if (search.value != "") {
                    search.value = "";
               }
          };

          const resetList = () => {
               list.value = providerData.value;
               clearSearch();
          };

          onMounted(async () => {
               if (providerData.value != null) {
                    list.value = providerData.value;
               }

               document.addEventListener("click", (evt) => {
                    const sortTrigger = [
                         document.querySelector(".dropdown-provider"),
                         document.querySelector(".dropdown-list"),
                         document.querySelector(".dropdown-check"),
                         document.querySelector(".dropdown-text"),
                         document.querySelector(".dropdown-trigger"),
                         document.querySelector(".dropdown-trigger-text"),
                         document.querySelector(".dropdown-trigger-down"),
                         document.querySelector(".dropdown-search"),
                         document.querySelector(".dropdown-search-clear"),
                         document.querySelector(".dropdown-search-wrapper")
                    ];

                    const target = document.querySelector(
                         "#dropdown-provider-list"
                    );

                    let targetElSort = evt.target; // clicked element

                    if (targetElSort != null) {
                         do {
                              if (sortTrigger.includes(targetElSort)) {
                                   return;
                              }
                              targetElSort = targetElSort.parentNode;
                         } while (targetElSort);
                    }

                    if (target) {
                         props.onDropdown(false);
                         resetList();
                    }
               });
          });

          return {
               providerData,
               list,
               search,
               onSearch,
               clearSearch,
               resetList
          };
     }
};
</script>
