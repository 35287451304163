<template>
     <div v-if="pagination?.data?.length != 0">
          <div class="flex flex-col -mt-2 mb-5 md:mb-10">
               <div class="flex flex-row items-center justify-between">
                    <div class="flex flex-row items-center gap-2">
                         <img
                              loading="lazy"
                              class="w-8"
                              :src="baseUrl.img + '/assets/img/lobby/coins.png'"
                              alt=""
                              srcset=""
                         />
                         <p
                              class="font-roboto font-bold text-white text-sm lg:text-lg"
                         >
                              Promotions
                         </p>
                    </div>
                    <div class="flex flex-row gap-2">
                         <div
                              @click="onPrev"
                              :class="
                                   currentSlide > 0
                                        ? 'cursor-pointer'
                                        : 'cursor-not-allowed'
                              "
                         >
                              <svg
                                   width="23"
                                   height="23"
                                   viewBox="0 0 23 23"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        :fill="
                                             currentSlide > 0
                                                  ? '#fff'
                                                  : '#6A374D'
                                        "
                                        d="M7.58219 11.8133C7.14057 11.3634 7.14057 10.6193 7.58219 10.1693L13.3062 4.33745C13.7478 3.88752 14.4782 3.88752 14.9198 4.33745C15.3614 4.78739 15.3614 5.53152 14.9198 5.98146L9.9941 11L14.9028 16.0185C15.3444 16.4685 15.3444 17.2126 14.9028 17.6625C14.4612 18.1125 13.7308 18.1125 13.2892 17.6625L7.56524 11.8307L7.58219 11.8133Z"
                                   />
                              </svg>
                         </div>
                         <div
                              @click="onNext"
                              :class="
                                   currentSlide < nextLimit
                                        ? 'cursor-pointer'
                                        : 'cursor-not-allowed'
                              "
                         >
                              <svg
                                   width="22"
                                   height="23"
                                   viewBox="0 0 22 23"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        :fill="
                                             currentSlide < nextLimit
                                                  ? '#fff'
                                                  : '#6A374D'
                                        "
                                        d="M14.8177 10.1866C15.2593 10.6366 15.2593 11.3807 14.8177 11.8307L9.09374 17.6625C8.65213 18.1125 7.92175 18.1125 7.48014 17.6625C7.03852 17.2126 7.03852 16.4685 7.48014 16.0185L12.4058 11L7.49714 5.98146C7.05552 5.53152 7.05552 4.78739 7.49714 4.33745C7.93875 3.88752 8.66908 3.88752 9.11069 4.33745L14.8347 10.1693L14.8177 10.1866Z"
                                   />
                              </svg>
                         </div>
                    </div>
               </div>
               <div class="mt-2">
                    <Carousel
                         :itemsToShow="1"
                         :wrapAround="false"
                         :mouseDrag="false"
                         :touchDrag="false"
                         snapAlign="start"
                         :transition="500"
                         class="home-slider-list"
                         :breakpoints="showItems"
                         v-model="currentSlide"
                    >
                         <Slide
                              v-for="(item, key) in pagination.data"
                              :key="key"
                         >
                              <!-- @click="() => goToDetail(item.game_name, item.id)" -->
                              <div class="w-full relative mx-1.5">
                                   <div
                                        class="w-full rounded-xl xl:rounded-lg bg-gradient-card relative p-4 md:p-6 xl:p-2 2xl:p-3 h-52 xs:h-60 sm:h-80 md:h-96 lg:h-60 xl:h-44 2xl:h-56 flex flex-col justify-between items-start cursor-pointer"
                                        @click="() => goto(item)"
                                   >
                                        <div>
                                             <h3
                                                  class="text-start text-white whitespace-nowrap font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-2xl xl:text-2xl 2xl:text-3xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-xl xl:leading-2xl 2xl:leading-3xl uppercase"
                                             >
                                                  <span>{{ item.title }}</span>
                                                  {{ item.title_highlight }}
                                             </h3>
                                             <p
                                                  v-html="item.sub_content"
                                                  class="text-moozi-14 font-roboto font-bold tracking-wide mt-2 xl:mt-1 text-start text-sm xs:text-lg sm:text-xl md:text-2xl lg:text-base xl:text-sm 2xl:text-base"
                                             ></p>
                                        </div>
                                        <div
                                             @click="() => goto(item)"
                                             class="h-10 sm:h-11 flex items-center w-max rounded-full font-roboto font-bold tracking-wider px-10 lg:px-6 bg-secondary-3 text-primary-1 text-sm cursor-pointer"
                                        >
                                             See More
                                        </div>
                                        <img
                                             :src="item.icon"
                                             :alt="item.title"
                                             class="bottom-0 right-0 absolute w-6/12 xs:w-5/12 sm:w-5/12 md:w-4/12 xl:w-4/12"
                                        />
                                   </div>
                              </div>
                         </Slide>
                    </Carousel>
               </div>
          </div>
     </div>
</template>
<script>
import { ref, computed, watch, reactive, onMounted } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import { baseUrl } from "config/services.js";
import { promoStore } from "store/promotion";
import { useRouter } from "vue-router";
import _ from "underscore";

export default {
     components: {
          Carousel,
          Slide
     },
     props: ["page"],
     setup() {
          const router = useRouter();

          const currentSlide = ref(0);
          const showItems = ref({
               0: {
                    itemsToShow: 1
               },
               1024: {
                    itemsToShow: 2
               },
               1280: {
                    itemsToShow: 3.45
               }
          });

          const nextLimit = ref(0);

          const pagination = reactive({
               data: []
          });

          const usePromoStore = promoStore();

          const promoData = computed(() => {
               if (usePromoStore.promo) {
                    pagination.data = usePromoStore.promo;
                    setNextLimit();
               }
               return usePromoStore.promo || null;
          });

          const goto = (item) => {
               router.push({
                    path:
                         "/promotion/detail/" +
                         item.title.replaceAll(" ", "-") +
                         "-" +
                         item.title_highlight.replaceAll(" ", "-") +
                         "-" +
                         item.id
               });
          };

          const onNext = () => {
               const current = currentSlide.value;
               if (current < nextLimit.value) {
                    currentSlide.value = current + 1;
               }
          };

          const onPrev = () => {
               const current = currentSlide.value;
               if (current > 0) {
                    currentSlide.value = current - 1;
               }
          };

          const adjustNextLimit = () => {
               window.addEventListener("resize", () => {
                    currentSlide.value = 0;
                    setNextLimit();
               });
          };

          const setNextLimit = () => {
               const getWidth = window.innerWidth;

               const find = _.filter(
                    Object.keys(showItems.value),
                    function (params) {
                         return getWidth >= parseInt(params);
                    }
               );

               let size = parseInt(find.pop());
               size = Math.round(showItems.value[size]["itemsToShow"]);

               nextLimit.value =
                    size >= pagination.data.length
                         ? 0
                         : pagination.data.length - size;
          };

          onMounted(async () => {
               setNextLimit;
               adjustNextLimit();
          });

          watch(promoData, (newVal) => {
               if (newVal) {
                    pagination.data = newVal;
                    setNextLimit();
               }
          });

          return {
               baseUrl,
               onNext,
               onPrev,
               currentSlide,
               promoData,
               goto,
               showItems,
               nextLimit,
               pagination
          };
     }
};
</script>
