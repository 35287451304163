import { isLogged } from "utils/auth.js";

export default function auth({ next, router }) {
     const token = isLogged();

     if (token) {
          return router.push({ name: "lobby" });
     }

     return next();
}
