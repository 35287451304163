import request from "@/utils/request";

export function getPromotion(data) {
     return request({
          url: "/promotion",
          method: "get",
          params: data
     });
}

export function banner() {
     return request({
          url: "/utils_banner",
          method: "get"
     });
}
