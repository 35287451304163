<template>
     <div class="my-3 mx-2">
          <div
               v-if="providerData && !loading"
               class="w-full h-10 lg:h-11 shadow-lg flex flex-row items-center gap-2 relative"
          >
               <SearchGame
                    :onSearch="onSearch"
                    :onClear="onClear"
               />
               <DropdownGame
                    :isDropdown="isDropdown"
                    :onDropdown="
                         (val) => {
                              onDropdown(val);
                         }
                    "
                    :selectedDropdown="selectedDropdown"
                    :onSelect="onSelect"
                    :hideDropdown="hideDropdown"
               />
               <SortByGame
                    :params="params"
                    :sortByOpen="sortByOpen"
                    :onSortByOpen="onSortByOpen"
                    :onSort="onSort"
               />
          </div>
          <div
               v-else
               class="w-full h-10 lg:h-11 shadow-lg flex flex-row items-center gap-2 relative"
          >
               <Skeletor
                    as="div"
                    class="!w-10/12 lg:!w-8/12 !h-full rounded-full"
               />
               <Skeletor
                    as="div"
                    class="!w-1/12 lg:!w-2/12 !h-full rounded-full"
               />
               <Skeletor
                    as="div"
                    class="!w-1/12 lg:!w-2/12 !h-full rounded-full"
               />
          </div>
     </div>
</template>
<script>
import SearchGame from "./components/search/index.vue";
import DropdownGame from "./components/dropdown/index.vue";
import SortByGame from "./components/sortby/index.vue";
import { ref, watch } from "vue";
import { computed } from "vue";
import { gamesStore } from "store/games";

export default {
     props: [
          "onSearch",
          "onClear",
          "isDropdown",
          "onDropdown",
          "selectedDropdown",
          "onSelect",
          "hideDropdown",
          "params",
          "sortByOpen",
          "onSortByOpen",
          "onSort"
     ],
     components: {
          SearchGame,
          DropdownGame,
          SortByGame
     },
     setup() {
          const loading = ref(false);

          const useGamesStore = gamesStore();

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          watch(providerData, () => {
               loading.value = true;
               setTimeout(() => {
                    loading.value = false;
               }, 1500);
          });

          return {
               loading,
               providerData
          };
     }
};
</script>
