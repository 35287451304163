import { isLogged } from "utils/auth.js";

export default async function auth({ next, router }) {
     const token = isLogged();

     if (!token) {
          router.replace({ name: "lobby", force: true });
          return next("/lobby");
     } else {
          return next();
     }
}
