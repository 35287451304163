<template>
     <div class="w-full h-full flex flex-col items-center justify-center">
          <h1
               :class="lightMode ? 'text-shade-7' : 'text-white'"
               class="text-center w-full font-roboto tracking-wide text-xs md:text-xs lg:text-sm xl:text-lg"
          >
               You are currently self excluded. This ends in
               {{
                    selfExclusionTimer != null
                         ? selfExclusionTimeFormat(selfExclusionTimer)
                         : "0 days"
               }}
               <br />
               If you think this was a mistake, or wish to change it, please get
               in touch with our online support.
          </h1>
          <img
               :src="baseUrl.img + '/assets/img/blade/restricted.png'"
               alt="cow"
               class="mt-8 md:mt-16 mb-8 h-58 lg:h-auto"
          />
     </div>
</template>
<script>
import { useRouter } from "vue-router";
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";
import { authStore } from "store/auth.js";
import { computed } from "vue";

export default {
     props: ["lightMode"],
     setup() {
          const router = useRouter();
          const { selfExclusionTimeFormat } = useFormatter();

          const useAuthStore = authStore();

          const selfExclusionTimer = computed(() => {
               return useAuthStore.selfExclusionTimer;
          });

          const goToResponsible = () => {
               router.push({
                    path: "/account/",
                    query: { tab: "responsible-play" }
               });
          };

          return {
               selfExclusionTimeFormat,
               goToResponsible,
               baseUrl,
               selfExclusionTimer
          };
     }
};
</script>
