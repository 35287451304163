<template>
     <Slider
          v-if="type == 'slider'"
          :data="games"
          title="Recent Played"
          :onNextSlide="onSeeMore"
          :pagination="pagination"
          :game_type="'recent'"
          :onSetActive="onSetActive"
     >
          <template v-slot:icon>
               <span v-html="decodeIcon(icon)"></span>
          </template>
     </Slider>
     <Paginate
          v-else-if="type == 'paginate'"
          :data="games"
          title="Recent Played"
          :onSeeMore="onSeeMore"
          :pagination="pagination"
          game_type="recent"
     >
          <template v-slot:icon>
               <span v-html="decodeIcon(icon)"></span>
          </template>
     </Paginate>
</template>
<script>
import { computed, reactive, watch } from "vue";
import { gamesStore } from "store/games";
import Slider from "components/games/slider/index.vue";
import Paginate from "components/games/paginate/index.vue";
import { useFormatter } from "composables/use-formatter.js";

export default {
     components: {
          Slider,
          Paginate
     },
     props: ["type", "onSetActive", "icon"],
     setup() {
          const useGamesStore = gamesStore();

          const { decodeIcon } = useFormatter();

          const games = reactive({
               data: null
          });

          const pagination = reactive({
               current: null,
               last: null,
               next: null
          });

          const params = reactive({});

          const recentData = computed(() => {
               if (useGamesStore.allRecent) {
                    games.data = useGamesStore.allRecent["data"];
                    pagination["current"] =
                         useGamesStore.allRecent["current_page"];
                    pagination["last"] = useGamesStore.allRecent["last_page"];
                    const temp = useGamesStore.allRecent["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.split("=")[1] : pagination["last"];
               }
               return useGamesStore.allRecent || null;
          });

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    params[obj[index]] = useGamesStore.gameFilter[obj[index]];
               }

               return useGamesStore.gameFilter;
          });

          const onSeeMore = async () => {
               const data = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: params.provider_id,
                    page: parseInt(pagination["next"]),
                    itemsPerPage: 21
               };

               await useGamesStore.getRecent(data, false, true);
          };

          watch(recentData, (newVal) => {
               if (newVal) {
                    games.data = newVal["data"];
                    pagination["current"] = newVal["current_page"];
                    pagination["last"] = newVal["last_page"];
                    const temp = newVal["next_page_url"];
                    pagination["next"] =
                         temp != null ? temp.split("=")[1] : pagination["last"];
               }
          });

          watch(gameFilter, () => {});

          return {
               games,
               onSeeMore,
               pagination,
               decodeIcon
          };
     }
};
</script>
